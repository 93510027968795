import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import QuoteSummary from "./QuoteSummary";

const PublicQuote = () => {
    const { publicCode } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const baseUrl = window.location.hostname === 'localhost' ? `http://${window.location.hostname}:8001` : window.location.origin;
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${baseUrl}/api/quote/${publicCode}`);
                console.log('API Response:', response.data); // Log the response
                if (response.data?.data) {
                    setData(response.data.data);
                } else {
                    setError('There is no quote data available');
                }
            } catch (err) {
                console.error('API Error:', err); // Log the error
                setError(err.response?.data?.message || err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [publicCode]);

    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!data) return <div>There is no quote data available</div>;

    return (
        <QuoteSummary data={data} />
    );
}

export default PublicQuote;