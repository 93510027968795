import { Grid, Button, Dialog, DialogTitle, DialogContent, Typography, IconButton, Slide, DialogActions, TextField } from "@material-ui/core"
import { useState, forwardRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { gql, useMutation } from "@apollo/client";
import Fab from '@material-ui/core/Fab';
import ShareIcon from '@material-ui/icons/Share';

const useStyles = makeStyles((theme) => ({
    title: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
}));

const GENERATE_QUERY = gql`
    mutation generatePublicLink($id: ID!, $noOfDays: Int) {
        generatePublicLink(id: $id, noOfDays: $noOfDays) {
            publicCode
            linkExpiresAt
        }
    }
`;

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PublicLink = ({id, publicCode, linkExpiresAt, updatePublicLink}) => {
    const [generatePublicLink] = useMutation(GENERATE_QUERY);
    const classes = useStyles();
    const [localPublicCode, setLocalPublicCode] = useState(publicCode);
    const [localLinkExpiresAt, setLocalLinkExpiresAt] = useState(linkExpiresAt);
    const [open, setOpen] = useState(false);
    const [validFor, setValidFor] = useState(7);

    const handleClose = () => {
        setOpen(false);
    }

    const handleGeneratePublicLink = async (validFor) => {
        console.log('generate public link', validFor);
        const {data} = await generatePublicLink({variables: {noOfDays: validFor, id}})
        if (data?.generatePublicLink) {
            setLocalPublicCode(data.generatePublicLink.publicCode);
            setLocalLinkExpiresAt(data.generatePublicLink.linkExpiresAt);
            updatePublicLink(data.generatePublicLink.publicCode, data.generatePublicLink.linkExpiresAt);
        }
    }
    const handleGenerate = () => {
        handleGeneratePublicLink(validFor);
    }

    return (
        <Grid container>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => setOpen(true)}>
                <ShareIcon />
            </Fab>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                TransitionComponent={Transition}
            >
                <DialogTitle variant={'h1'} className={classes.title} id="responsive-dialog-title">
                    <Typography variant="h6">
                        Generate Public Link
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        {
                            localPublicCode && 
                            new Date(localLinkExpiresAt) > new Date() && (
                                <Grid item xs={12}>
                                    <Typography>
                                        A Public Link has been generated <a target="_blank" href={`${window.location.origin}/quote/${localPublicCode}`}>Here</a> and will expire on {new Date(localLinkExpiresAt).toLocaleDateString()}
                                    </Typography>
                                    <Typography style={{marginTop: '16px', fontStyle: 'italic'}}>
                                        - OR -
                                    </Typography>
                                </Grid>
                            )
                        }
                        <Grid item xs={12} style={{marginTop: '16px'}}>
                            <Typography>
                                Generate {localPublicCode ? 'a new' : 'a'} public link that can be used to view the takeoff.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '16px'}}>
                            <TextField
                                label="Valid for (Days)"
                                fullWidth
                                type="number"
                                variant="outlined"
                                value={validFor}
                                onChange={(e) => setValidFor(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={handleGenerate}>Generate</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default PublicLink;