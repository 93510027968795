import React from "react";
import { Switch, Route } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import ScrollToTop from "./ScrollToTop";
import NewTakeoff from "./NewTakeoff";
import UpdateTakeoff from "./UpdateTakeoff";
import Takeoffs from "./Takeoffs";
import NewUser from "./NewUser";
import ProfileForm from "./ProfileForm";
import UpdateUser from "./UpdateUser";
import Users from "./Users";
import ConfirmUser from "./ConfirmUser";
import Login from './Login'
import Import from './Import'
import ExportData from './ExportData'
import PrivateRoute from './common/private_route'
import { NODE_ENV, REACT_APP_API_HOST } from './envs';
import PublicQuote from "./Quote/PublicQuote";
const Main = () => {
    const base = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
    
    return (
        <React.Fragment>
            <CssBaseline />
            <ScrollToTop>
                <Switch>
                    <PrivateRoute basename={base} exact path="/" component={Takeoffs} />
                    <PrivateRoute basename={base} path="/newTakeoff" component={NewTakeoff} />
                    <PrivateRoute basename={base} path="/updateTakeoff/:id" component={UpdateTakeoff} />
                    <PrivateRoute basename={base} exact path="/users" component={Users} />
                    <PrivateRoute basename={base} path="/newUser" component={NewUser} />
                    <PrivateRoute basename={base} path="/profile" component={ProfileForm} />
                    <PrivateRoute basename={base} path="/updateUser/:id" component={UpdateUser} /> 
                    <PrivateRoute basename={base} path="/import" component={Import} />
                    <PrivateRoute basename={base} path="/export" component={ExportData} />
                    <Route basename={base} path="/confirm/:token" component={ConfirmUser} />
                    <Route basename={base} path="/login" component={Login} />
                    <Route basename={base} path="/confirm/:token" component={ConfirmUser} />
                    <Route basename={base} path="/forgot-password" component={() => <Login forgetPassword={true} />} />
                    <Route basename={base} path="/quote/:publicCode" component={PublicQuote} />
                </Switch>
            </ScrollToTop>
        </React.Fragment>
    )
}
export default Main;