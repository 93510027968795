import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import * as Lib from '../lib/lib';
import Divider from "@material-ui/core/Divider";
import {SummaryLabel} from '../../common/hooks';
import ProductState from '../Measure/ProductState';
import {installItems} from '../../common/constants';
import TakeoffInfoSummary from './TakeoffInfoSummary';

const useStyles = makeStyles((theme) => ({
    list: {
        position: "relative",
        overflow: "auto",
        width: '100%',
        paddingBottom: 0,
        boxShadow: theme.shadows[5] + '!important',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    listSubheader:{
        backgroundColor: theme.palette.grey[300],
        color: "black",
        paddingTop: "8px",
        paddingBottom: "8px",
        borderBottom: "1px solid " + theme.palette.grey[300]
    },
    summaryDoorListItem: {
        borderBottom: "2px solid " + theme.palette.grey[300] + '!important',
        backgroundColor: 'white'
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    subTotal:{
        // backgroundColor: theme.palette.grey[200],
    },
    summaryFooter: {
        backgroundColor: theme.palette.primary.main,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '16px',
        zIndex: 10
    },
    summaryFooterContainer: {
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    footerText: {
        color: theme.palette.grey[100]
    },
    negativeItem: {
        backgroundColor: theme.palette.secondary[100]
    },
}));

const SummaryTable = (props) => {
    const {
        loading,
        items,
        tier,
        cpu,
        installPricingMode,
        installAt,
        door_treatment,
        customerType,
        customer,
        site,
        code,
        creator,
        specProducts = {},
    } = props;

    const {
        doors,
        trim,
        machine,
        wire_shelving,
        shelving,
        sheet,
        prices,
        notes,
        has,
        opening = {},
        opening_count,
        extra,
        wire_install = {},
    } = items;

    const {
        doors:          doors_price,
        trim:           trim_price,
        wire_shelving:  wire_shelving_price,
        wire_install:   wire_install_price,
        shelving:       shelving_price,
        sheet:          sheet_price,
        extra:          extra_price,
        opening:        opening_price,
    } = prices;
    const classes = useStyles();

    const total = !loading && Object.keys(prices).reduce((sum,key) => sum + parseFloat(prices[key]||0),0);

    const doorsList = 
    Object.keys(doors).map( type => (
        Object.keys(doors[type])
        .sort(function(a, b) {
            let z = 1;
            if ('width' in doors[type][a]) {
                if (doors[type][b]['width'] > doors[type][a]['width']) z = -1
                else z = 1
            }
            return z;
        })
        .map(code => {
            const count = ['Pair', 'PR'].includes(doors[type][code].uom) ? Math.ceil(doors[type][code].count/2) : doors[type][code].count;
            return (
                <React.Fragment key={code}>
                    <ListItem className={classes.summaryDoorListItem}>
                        <Grid  container spacing={1}>
                            <Grid item xs={5} style={{display: 'flex'}}>
                                <ProductState cpu={cpu} code={code} />
                                <SummaryLabel type={type !== 'doors' && type} code={code} />
                            </Grid>
                            <Grid item xs={2}><Typography>{count} {doors[type][code].uom || 'EA'}</Typography></Grid>
                            <Grid item xs={2}><Typography>${Number(doors[type][code].price).toFixed(2)}</Typography></Grid>
                            <Grid item xs={3}><Typography>${Number(doors[type][code].price * count).toFixed(2)}</Typography></Grid>
                            <Grid item xs={12}><Typography variant="caption">{doors[type][code].description}</Typography></Grid>
                        </Grid>
                    </ListItem>
                    <Divider />
                </React.Fragment>
            )
        })
    ));

    return (
        <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TakeoffInfoSummary
                        showImages
                        label="Order Summary"
                        price={total}
                        notes={notes}
                    />
                    <TakeoffInfoSummary
                        isPrint
                        showImages
                        label="Order Summary"
                        installType={installPricingMode}
                        installAt={installAt}
                        door_treatment={door_treatment}
                        customerType={customerType}
                        customer={customer}
                        site={site}
                        code={code}
                        creator={creator}
                        price={total}
                        notes={notes}
                    />
                </Grid>
                <Grid item xs={12}>
                    <List className={classes.list+ " groupedList"} subheader={<li />}>
                        {
                            has.trim &&
                            <React.Fragment>
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="body1">Trim</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Length</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                    </Grid>
                                </ListSubheader>
                                {
                                    Object.keys(trim).map( type => {
                                        return Object.keys(trim[type]).map( code => {
                                            return (
                                                <div key={type+'_'+code}>
                                                    <ListItem className={classes.summaryDoorListItem}>
                                                        <Grid  container spacing={1}>
                                                            <Grid  style={{display: 'flex'}} item xs={5}>
                                                                <ProductState cpu={cpu} code={code} />
                                                                <SummaryLabel code={code} type={type} />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography>
                                                                    { 
                                                                        // code.startsWith('MDF-S4S') ? `${Math.ceil(trim[type][code]['count']) / parseInt(code.replace('__LF', '').slice(-2))} PCs`
                                                                        // : 
                                                                        trim[type][code]['uom'] === 'LF' ? 
                                                                        Math.ceil(trim[type][code]['count']) : trim[type][code]['count']
                                                                    } 
                                                                    {
                                                                        // code.startsWith('MDF-S4S') ? "" : 
                                                                        ` ${trim[type][code]['uom']}`
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography>
                                                                    ${Number(trim[type][code]['price']).toFixed(2)} 
                                                                    {
                                                                        // code.startsWith('MDF-S4S') ? "/LF" : ""
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography>
                                                                    ${
                                                                        Number((trim[type][code]['uom'] === 'LF' ? Math.ceil(trim[type][code]['count']) : trim[type][code]['count']) * trim[type][code]['price']).toFixed(2)
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            {
                                                                'description' in trim[type][code] &&
                                                                <Grid item xs={12}><Typography variant="caption">{trim[type][code]['description']}</Typography></Grid>
                                                            }
                                                        </Grid>
                                                    </ListItem>
                                                </div>
                                            )
                                        })
                                    })
                                }
                                <div>
                                    <ListItem className={classes.subTotal}>
                                        <Grid  container spacing={1}>
                                            <Grid item xs={9}><Typography variant="subtitle2">Trim Subtotal</Typography></Grid>
                                            <Grid item xs={3}><Typography variant="subtitle2">${Number(trim_price.toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                        </Grid>
                                    </ListItem>
                                </div>
                            </React.Fragment>
                        }
                        {
                            has.doors &&
                            <React.Fragment>
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="body1">Doors</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">No.</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                    </Grid>
                                </ListSubheader>
                                { doorsList }
                                {
                                    machine &&
                                    Object.keys(machine).map( (item, i) => {
                                        return (
                                            <ListItem key={i} className={classes.summaryDoorListItem}>
                                                <Grid  container spacing={1}>
                                                    <Grid item xs={5}><SummaryLabel code={item} /></Grid>
                                                    <Grid item xs={2}><Typography>{machine[item].count} {Lib.uoms(machine[item].uom)}</Typography></Grid>
                                                    <Grid item xs={2}><Typography>${Number(machine[item].price).toFixed(2)}</Typography></Grid>
                                                    <Grid item xs={3}><Typography>${Number(machine[item].price * machine[item].count).toFixed(2)}</Typography></Grid>
                                                    <Grid item xs={12}><Typography variant="caption">{machine[item].description}</Typography></Grid>
                                                </Grid>
                                            </ListItem>
                                        )
                                    })
                                }
                                <div>
                                    <ListItem className={classes.subTotal}>
                                        <Grid  container spacing={1}>
                                            <Grid item xs={9}><Typography variant="subtitle2">Doors Subtotal</Typography></Grid>
                                            <Grid item xs={3}><Typography variant="subtitle2">${Number((doors_price).toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                        </Grid>
                                    </ListItem>
                                </div>
                            </React.Fragment>
                        }
                        {
                            (has.shelving || has.wire_shelving) &&
                            <React.Fragment>
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="body1">Shelf</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">QTY</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                    </Grid>
                                </ListSubheader>
                                {
                                    Object.keys(wire_shelving).map( (code) => (
                                            <div key={code}>
                                                <ListItem className={classes.summaryDoorListItem}>
                                                    <Grid  container spacing={1}>
                                                        <Grid item xs={5}  style={{display: 'flex'}}>
                                                            <ProductState cpu={cpu} code={code} />
                                                            <SummaryLabel type={'Wire'} code={code} />
                                                        </Grid>
                                                        <Grid item xs={2}><Typography>{Math.ceil(wire_shelving[code]['count'])} {wire_shelving[code]['uom']}</Typography></Grid>
                                                        <Grid item xs={2}><Typography>${Number(wire_shelving[code]['price']).toFixed(2)}</Typography></Grid>
                                                        <Grid item xs={3}><Typography>${Number(Math.ceil(wire_shelving[code]['count']) * wire_shelving[code]['price']).toFixed(2)}</Typography></Grid>
                                                        {
                                                            'description' in wire_shelving[code] &&
                                                            <Grid item xs={12}><Typography variant="caption">{wire_shelving[code]['description']}</Typography></Grid>
                                                        }
                                                    </Grid>
                                                </ListItem>
                                            </div>
                                        ))
                                }
                                {
                                    Object.keys(shelving).map( (type) => (
                                        Object.keys(shelving[type]).map( (code) => {
                                            const count = type === 'cleat' ? 
                                            `${Math.ceil(shelving[type][code]['count'] / parseInt(code.slice(-2).trim()))} PCs` : 
                                            `${shelving[type][code]['count']} ${shelving[type][code]['uom']}`
                                            return (
                                                <div key={type+code}>
                                                    <ListItem className={classes.summaryDoorListItem}>
                                                        <Grid  container spacing={1}>
                                                            <Grid item xs={5} style={{display: 'flex'}}>
                                                                <ProductState cpu={cpu} code={code} />
                                                                <SummaryLabel type={type} code={code} />
                                                            </Grid>
                                                            <Grid item xs={2}><Typography>{count}</Typography></Grid>
                                                            <Grid item xs={2}><Typography>${Number(shelving[type][code]['price']).toFixed(2)}</Typography></Grid>
                                                            <Grid item xs={3}><Typography>${Number(shelving[type][code]['count'] * shelving[type][code]['price']).toFixed(2)}</Typography></Grid>
                                                            {
                                                                'description' in shelving[type][code] &&
                                                                <Grid item xs={12}><Typography variant="caption">{shelving[type][code]['description']}</Typography></Grid>
                                                            }
                                                        </Grid>
                                                    </ListItem>
                                                </div>
                                        )})
                                    ))
                                }
                                <div>
                                    <ListItem className={classes.subTotal}>
                                        <Grid  container spacing={1}>
                                            <Grid item xs={9}><Typography variant="subtitle2">Shelving Subtotal</Typography></Grid>
                                            <Grid item xs={3}><Typography variant="subtitle2">${Number((shelving_price+wire_shelving_price).toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                        </Grid>
                                    </ListItem>
                                </div>
                            </React.Fragment>
                        }
                        {
                            Object.keys(sheet).length > 0
                            &&
                            <React.Fragment>
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="body1">Sheet</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                    </Grid>
                                </ListSubheader>
                                {
                                    Object.keys(sheet).map( code => {
                                        return (
                                            <div key={code}>
                                                <ListItem className={classes.summaryDoorListItem}>
                                                    <Grid  container spacing={1}>
                                                        <Grid item xs={5} style={{display: 'flex'}}>
                                                            <ProductState cpu={cpu} code={code} />
                                                            <SummaryLabel code={code} type={'Sheet'} />
                                                        </Grid>
                                                        <Grid item xs={2}><Typography>{sheet[code]['count'] } {sheet[code]['uom']}</Typography></Grid>
                                                        <Grid item xs={2}><Typography>${Number(sheet[code]['price']).toFixed(2)}</Typography></Grid>
                                                        <Grid item xs={3}><Typography>${Number(sheet[code]['count'] * sheet[code]['price']).toFixed(2)}</Typography></Grid>
                                                        {
                                                            'description' in sheet[code] &&
                                                            <Grid item xs={12}><Typography variant="caption">{sheet[code]['description']}</Typography></Grid>
                                                        }
                                                    </Grid>
                                                </ListItem>
                                            </div>
                                        )
                                    })
                                }
                                <div>
                                    <ListItem className={classes.subTotal}>
                                        <Grid  container spacing={1}>
                                            <Grid item xs={9}><Typography variant="subtitle2">Sheet Subtotal</Typography></Grid>
                                            <Grid item xs={3}><Typography variant="subtitle2">${Number(sheet_price.toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                        </Grid>
                                    </ListItem>
                                </div>
                            </React.Fragment>
                        }
                        {/*
                            machine &&
                            Object.keys(machine).length > 0 &&
                            <React.Fragment>
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="body1">Machine</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                    </Grid>
                                </ListSubheader>

                                <div>
                                    <ListItem className={classes.subTotal}>
                                        <Grid  container spacing={1}>
                                            <Grid item xs={9}><Typography variant="subtitle2">Machine Subtotal</Typography></Grid>
                                            <Grid item xs={3}><Typography variant="subtitle2">${Number(machine_price.toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                        </Grid>
                                    </ListItem>
                                    <Divider/>
                                </div>
                            </React.Fragment>
                        */}
                        {
                            (
                                (
                                    opening_count > 0 &&
                                    Lib.deepAttributeExists(['opening_service', 'code'], specProducts)
                                ) ||
                                (extra && Object.keys(extra).length > 0 ) ||
                                has.wire_install
                            ) &&
                            <ListSubheader className={classes.listSubheader}>
                                <Grid container spacing={1}>
                                    <Grid item xs={5}><Typography variant="body1">Install</Typography></Grid>
                                    <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                    <Grid item xs={2}><Typography variant="body1">Price</Typography></Grid>
                                    <Grid item xs={3}><Typography variant="body1">Total</Typography></Grid>
                                </Grid>
                            </ListSubheader>
                        }
                        {
                            opening_count > 0 &&
                            Lib.deepAttributeExists(['opening_service', 'code'], specProducts) &&
                            <div>
                                <ListItem className={classes.summaryDoorListItem}>
                                    <Grid  container spacing={1}>
                                        <Grid item xs={5}><SummaryLabel code={specProducts.opening_service.code} /></Grid>
                                        <Grid item xs={2}><Typography>{opening_count} {Lib.uoms(specProducts.opening_service.uom)}</Typography></Grid>
                                        <Grid item xs={2}><Typography>${Number(specProducts.opening_service.price[tier]).toFixed(2)}</Typography></Grid>
                                        <Grid item xs={3}><Typography>${Number(specProducts.opening_service.price[tier] * opening_count).toFixed(2)}</Typography></Grid>
                                        <Grid item xs={12}><Typography variant="caption">{specProducts.opening_service.description}</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            </div>
                        }
                        {
                            opening_count > 0 &&
                            Lib.deepAttributeExists(['opening_service', 'code'], specProducts) &&
                            Object.keys(opening).map( (code, i) => {
                                return (
                                    <div key={i}>
                                        <ListItem className={classes.summaryDoorListItem}>
                                            <Grid  container spacing={1}>
                                                <Grid item xs={5}><SummaryLabel code={Lib.humanize(installItems[code], true)} /></Grid>
                                                <Grid item xs={2}><Typography>{opening[code]['count']} EA</Typography></Grid>
                                                <Grid item xs={2}><Typography>$0.00</Typography></Grid>
                                                <Grid item xs={3}><Typography>$0.00</Typography></Grid>
                                                <Grid item xs={12}><Typography variant="caption">****{Lib.humanize(code, true)}****</Typography></Grid>
                                            </Grid>
                                        </ListItem>
                                    </div>
                                )
                            })
                        }
                        {
                            extra &&
                            Object.keys(extra).map( (code, i) => {
                                return (
                                    <div key={i}>
                                        <ListItem className={classes.summaryDoorListItem}>
                                            <Grid  container spacing={1}>
                                                <Grid item xs={5}><SummaryLabel code={code} /></Grid>
                                                <Grid item xs={2}><Typography>{extra[code].count}  {Lib.uoms(extra[code].uom) }</Typography></Grid>
                                                <Grid item xs={2}><Typography>${Number(extra[code].price).toFixed(2)}</Typography></Grid>
                                                <Grid item xs={3}><Typography>${Number(extra[code].count * extra[code].price).toFixed(2)}</Typography></Grid>
                                                <Grid item xs={12}><Typography variant="caption">{extra[code].description}</Typography></Grid>
                                            </Grid>
                                        </ListItem>
                                    </div>
                                )
                            })
                        }
                        {
                            has.wire_install &&
                            Object.keys(wire_install).map( (code, i) => {
                                return (
                                    <div key={i}>
                                        <ListItem className={classes.summaryDoorListItem}>
                                            <Grid  container spacing={1}>
                                                <Grid item xs={5}><SummaryLabel code={code} /></Grid>
                                                <Grid item xs={2}><Typography>{Math.ceil(wire_install[code].count)}  {Lib.uoms(wire_install[code].uom) }</Typography></Grid>
                                                <Grid item xs={2}><Typography>${Number(wire_install[code].price).toFixed(2)}</Typography></Grid>
                                                <Grid item xs={3}><Typography>${Number(Math.ceil(wire_install[code].count) * wire_install[code].price).toFixed(2)}</Typography></Grid>
                                                <Grid item xs={12}><Typography variant="caption">{wire_install[code].description}</Typography></Grid>
                                            </Grid>
                                        </ListItem>
                                    </div>
                                )
                            })
                        }
                        {
                            (
                                opening_count > 0 ||
                                (extra && Object.keys(extra).length > 0 ) ||
                                has.wire_install
                            ) &&
                            <div>
                                <ListItem className={classes.subTotal}>
                                    <Grid  container spacing={1}>
                                        <Grid item xs={9}><Typography variant="subtitle2">Install Subtotal</Typography></Grid>
                                        <Grid item xs={3}><Typography variant="subtitle2">${Number((opening_price+extra_price+wire_install_price).toFixed(2)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            </div>
                        }
                    </List>
                </Grid>
                
            </Grid>
    )
}

export default SummaryTable;