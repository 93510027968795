import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SummaryTable from './SummaryTable';
import useTakeoffParser from '../lib/useTakeoffParser'
import PublicLink from './PublicLink';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
        marginBottom: '100px',
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    noPrint: {
        '@media print': {
            display: 'none',
        }
    },
    
}));

const SummaryList = ({ context }) => {
    const classes = useStyles();
    const {cpu} = context.state;
    const tier  = context.getPricingTier();
    const [state, loading] = useTakeoffParser();
    const customer = context.getCustomer() || {};
    
    if (loading) return (<div>Loading</div>);
    // console.log(doorsList)
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SummaryTable 
                        loading={loading}
                        items={state}
                        tier={tier}
                        cpu={cpu}
                        site={context.state.site}
                        code={context.state.code}
                        creator={context.state.creator_name}
                        installPricingMode={context.state.installPricingMode}
                        installAt={context.state.installAt}
                        door_treatment={context.state.specs.door_treatment}
                        customerType={context.state.customerType}
                        customer={customer}
                        specProducts={context.state.specs.products}
                    />
                </Grid>
                <Grid item xs={12} className={classes.noPrint}>
                    <PublicLink 
                        id={context.state.takeoffEditId} 
                        publicCode={context.state.publicCode} 
                        linkExpiresAt={context.state.linkExpiresAt} 
                        updatePublicLink={context.updatePublicLink} 
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default SummaryList