import React from "react";
import { withRouter } from "react-router-dom";
import * as Lib from "./NewTakeoff/lib/lib";
import update from "immutability-helper";
import { defaultPricingTier } from './common/constants';
import { humanize } from "./common/lib";

let TakeoffFormContext
const {Provider, Consumer} = TakeoffFormContext = React.createContext();

const specs = {
    door_treatment: {
        prep: "",
        cutdown: "",
        backset: "",
        head: "",
    },
    door: {
        type: "",
        style: "",
        core: "",
        thickness: "",
        height: ""
    },
    shelving: {
        wood:  {},
        wire:  {},
        organizer:  {},
    },
    hardware: {
        knob : {},
        lever : {},
        gripset : {},
        deadbolt : {},
        closer : {},
        hinge : {},
        flushbolt : {},
        catches : {},
        doorstopper : {},
        pocket : {},
        barn_door_track : {},
        bipass_door_track : {},
        finger_pull : {},
        rod : {},
        handrail_bracket : {},
    },
    sheet: {},
    trim: {
        architrave: {},
        backband: {},
        baseboard: {},
        burlap: {},
        buildout: {},
        casing: {},
        curve: {},
        doorstop: {},
        d4s: {},
        crown_moulding: {},
        flat_stock: {},
        jamb: {},
        jamb_84: {},
        jamb_96: {},
        sill: {},
        shoe_moulding: {},
        quarter_round: {},
        cove: {},
        handrail: {},
        sheet: {},
        attic_foam_tape: {},
        attic_hatch: {},
        attic_lid: {},
        shiplap: {},
        shim: {},
        chair_rail: {},
        column: {}
    },
    products:{
        hinge: {},
        flushbolt: {},
        shoe_moulding: {},
        catches: {},
        casing: {},
        doorstop: {},
        backband: {},
        gripset: {},
        deadbolt: {},
        closer: {},
        barn_door_track : {},
        bipass_door_track : {},
        finger_pull : {},
        doorstopper : {},
        curve: {},
        wood: {},
        wire: {},
        d4s: {},
        baseboard: {},
        crown_moulding: {},
        buildout: {},
        burlap: {},
        sill: {},
        flat_stock: {},
        jamb: {},
        jamb_84: {},
        jamb_96: {},
        quarter_round: {},
        cove: {},
        handrail_bracket: {},
        handrail: {},
        attic_foam_tape: {},
        attic_hatch: {},
        attic_lid: {},
        shiplap: {},
        shim: {},
        sheet: {},
        chair_rail: {},
        column: {}
    },
};
const measureState = {
    door: {
        itemIndex: 0,
        unitNumber: "",
        title: "",
        width: "",
        doorFunction: "",
        operating: "",
        handleDirection: "",
        handleType: "",
        handleMode: "",
        notes: "",
        materialType: "",
        quantity: "",
        openingDepth: 0,
        jambWidth: "",
        architraveWidth: 0,
        cutdownHeight: 0,
        extraService: 0,
        hingesPerDoor: "",
        cutdownLocation: "",
        pocket_mode: "",
        doorstopper_mode: "",
        doorstopper_mode_left: "",
        doorstopper_mode_right: "",
        hasCutdown: false,
        hasExtraService: false,
        isHalfTrim: false,
        isSpecial: false,
        isBifold: false,
        isDouble: false,
        addBackband: false,
        addArchitrave: false,
        isReverse: false,
        hasHardware: false,
        latching: "",
        images: [],
        images_preview: [],
        new_images_preview: [],
        trim: {},
        hardware: {},
        additionalItem: {},
    },
    hardware: {
        knob : {},
        lever : {},
        gripset : {},
        deadbolt : {},
        closer : {},
        flushbolt : {},
        catches : {},
        hinge : {}
    },
    trim: {
        baseboard: [],
        casing: {},
        crown_moulding: [],
        shoe_moulding: [],
        architrave: {},
        jamb: {},
        backband: {},
        doorstop: {},
        burlap: {}
    },
    custom: {
        custom_product: {
            type: "",
            code: "",
            count: 1,
            notes:  "",
            images: [],
        },
        custom_install_service: {
            type: "",
            code: "",
            count: 1,
            notes:  "",
            images: [],
        },
        window: {
            itemIndex: 0,
            unitNumber: "",
            title: "",
            type: "",
            size: "",
            hardwareOptions: "",
            jambWidth: "",
            materialType: "",
            headerMaterial: "",
            highOpeningLocation: "",
            headerQuantity: "",
            backbandSides: "",
            architraveSides: "",
            doorstopper_mode: '',
            doorstopper_mode_left: '',
            doorstopper_mode_right: '',
            openingDepth: 0,
            extraService: 0,
            width: 0,
            height: 0,
            count: 0,
            quantity: "",
            layout: "",
            customHeader: false,
            hasExtraService:   false,
            isHigh: false,
            addSplit: false,
            addSill: false,
            hasBurlap: false,
            hasCloser: false,
            addBackband: false,
            addBuildout: false,
            addArchitrave: false,
            buildoutDepth: 0,
            closerCount: 1,
            sillDepth: 0,
            sillLength: 0,
            architraveWidth:    0,
            materialLength:     0,
            d4sLength:          0,
            casingLength:       0,
            handrailLength:     0,
            continuousHandrail: false,
            addHandrail:        false,
            addCasing:          false,
            addD4s:             false,
            notes:  "",
            images: [],
            additionalItem: {},
            hardware: {
                gripset: {
                    brand: "",
                    style: "",
                    finish: "",
                },
                interior: {
                    brand: "",
                    type: "",
                    finish: "",
                    style: "",
                    direction: "",
                    mode: "Interior",
                },
                deadbolt: {
                    brand: "",
                    style: "",
                    finish: "",
                },
                passage: {
                    brand: "",
                    type: "",
                    finish: "",
                    style: "",
                    direction: "",
                    mode: "Passage",
                },
                entry: {
                    brand: "",
                    type: "",
                    finish: "",
                    style: "",
                    mode: "Entry",
                },
                closer: {
                    brand: "",
                    style: "",
                    finish: "",
                },
            }
        },
        trim: {
            itemIndex: 0,
            unitNumber: "",
            title: "",
            attributes: {},
            type: "",
            width: "",
            thickness: "",
            notes:  "",
            images: [],
            additionalItem: {},
            count: 0
        },
        hardware: {
            itemIndex: 0,
            unitNumber: "",
            title: "",
            attributes: {},
            type: "",
            notes:  "",
            images: [],
            additionalItem: {},
            count: 0
        },
        shelving: {
            itemIndex: 0,
            unitNumber: "",
            title: "",
            type: "",
            depth: "",
            quantity: "",
            width: "",
            notes:  "",
            images: [],
            accessories: [],
            additionalItem: {},
            dimensions: {},
            miscellaneous: {},
            rod_finish: "",
            additional_brackets: 0,
            additional_rods:0,
            hasInstall: false
        }
    }
};

class FormProvider extends React.Component {
    constructor(props) {
        super(props);
        const url = props.history.location.pathname;
        let primaryTab = null;
        if (!url || !this.urlHasTab(url)) {
            primaryTab = 'customer'
            this.setPrimaryTab('customer', false)
        } else {
            const tab = url.substring(url.lastIndexOf('/') + 1);
            primaryTab = tab || 'customer';
        }

        this.state = {
            cpu: null,
            loading: false,
            error: false,
            isLocked: false,
            lockedAt: null,
            loadingModal: props.takeoffid ? true : false,
            customerType: "",
            customer: {
                name: "",
                accountNo: "",
                pricingTier: ""
            },
            prospect: {},
            uploader : null,
            updateSpecsDialog: false,
            specsDoorsUpdateMap: [],
            takeoffid: props.takeoffid ? props.takeoffid : "",
            takeoffEditId: "",
            name: "",
            status: "",
            notes: "",
            installPricingMode: "",
            code: "",
            primaryTab: primaryTab,
            specsTab: 0,
            measureTab: "door",
            customerKeyword: "",
            addEditItem: false,
            productUpdated: 0,
            takeoffSaved: false,
            specsChanged: false,
            measureDrawer: false,
            addDoorDialog: false,
            addMeasureItemSnack : false,
            formSubmitted: false,
            customerListOpen: false,
            specsDoorsUpdated: false,
            specialSpecsExpanded: false,
            deleteDoorconfirmOpen: false,
            editDoorIndex: -1,
            editTrimIndex: -1,
            editWindowIndex: -1,
            editShelvingIndex: -1,
            editHardwareIndex: -1,
            deleteTrimType: -1,
            deleteTrimIndex: -1,
            deleteDoorIndex: -1,
            deleteWindowIndex: -1,
            deleteHardwareIndex: -1,
            deleteShelvingIndex: -1,
            addDoorData: "",
            measureItemMessage: "",
            site: {},
            specs: specs,
            specialSpecs: specs,
            deleteMeasureItem: {},
            duplicateMeasureItem: {},
            measures: measureState,
            doors: [],
            hardware: [],
            trim: [],
            windows: [],
            shelving: [],
        };
    }

    componentDidMount() {
        const {takeoffid, apolloClient} = this.props;
        if (takeoffid) {
            this.refreshTakeoffData(takeoffid, apolloClient)
        }
    }

    sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    refreshTakeoffData = (takeoffid, apolloClient) => {
        const takeoffPromise = Lib.getTakeoff(takeoffid, apolloClient, true);
        takeoffPromise.then( (takeoff) => {
            if (takeoff && takeoff.id && takeoff.code) {
                this.populateTakeoff(takeoff)
                const reportPromise = Lib.getLatestCPU(takeoffid, apolloClient);
                reportPromise.then( async (report) => {
                    if( report && !report.isResolved ) {
                        await this.sleep(1500);
                        const priceChanged = report?.report?.prices_change.map(item => item.code)
                        const discontinued  = report?.report?.discontinued.map(item => item.code)
                        this.setState({
                            cpu: {
                                priceChanged,
                                discontinued
                            }
                        })
                    }
                })
            } else {
                this.setState({error: true})
            }
        });
    }

    isDoneTakeoff = () => this.state.status === 'sold' || (localStorage.getItem('role') === 'foreman' && this.state.status === 'estimate' || this.state.isLocked);

    urlHasTab = (url) => {
        if (
            url.indexOf('customer') > -1 ||
            url.indexOf('specs')    > -1 ||
            url.indexOf('summary')  > -1 ||
            url.indexOf('measure')  > -1    
        ) return true;
        else return false;
    }

    copySpecs = (from, to, category, type = "", attributes = null) => {
        if (type) {
            attributes && delete attributes.category;
            attributes && delete attributes.type;
            this.setState(prevState => {
                return {
                    [to]: update(prevState[to],{
                        [category]: {
                            $merge: {[type]: attributes ? attributes : prevState[from][category][type]}
                        }
                    })
                }
            })
        } else {
            attributes && delete attributes.category;
            this.setState(prevState => {
                return {
                    [to]: update(prevState[to],{
                        [category]: {$merge: attributes ? attributes : prevState[from][category]}
                    })
                };
            })
        }
    };

    getSpecsAttributeValue = (attribute, category, type = "") => {
        const specs = type ? this.state.specs[category][type] : this.state.specs[category];
        const specialSpecs = type ? this.state.specialSpecs[category][type] : this.state.specialSpecs[category];
        return attribute in specialSpecs && Lib.hasValue(specialSpecs[attribute]) ? specialSpecs[attribute] :
                attribute in specs && Lib.hasValue(specs[attribute]) ? specs[attribute] : null
    };

    validateTakeoff = () => {
        const { apolloClient } = this.props;
        this.setState({loading: true})
        let validatedTakeoffPromise = Lib.validateTakeoff(this.state.takeoffEditId, apolloClient);
        validatedTakeoffPromise.then(takeoff => {
            if (takeoff) {
                this.populateTakeoff(takeoff);
            }
        })
    };

    isSpecSet = (field, category, type="", rel= 'specs') => {
        return Lib.isSpecSet(field, this.state[rel][category], type);
    };

    updateStandardProducts = (category, type = "") => {
        // Custom Measure
        const product = this.state.specialSpecs.products[type] || {};
        const items = type === "" ? this.state[category] : type in this.state[category] ? this.state[category][type] : [];
        const newItems = items.map( item => {
            if ( item.isSpecial) {
                return item
            }
            return {
                code: product.code,
                description: product.description,
                price: product.price,
                uom: product.uom,
                count: item.count,
                isSpecial: false,
            }
        });
        type === "" ?
            this.setState({[category]: newItems }) :
            this.setState({
                [category]: {
                    ...this.state[category],
                    [type]: newItems
                }
            });
        if (["casing", "backband"].includes(type)) {  // Windows
            let newWindows = this.state.windows.length > 0 ? JSON.parse(JSON.stringify(this.state.windows)) : [];
            this.state.windows.forEach( (window, index) => {
                if (category in window && type in window[category] && "count" in window[category][type]) {
                    let newWindow = update(window[category][type], {
                        $merge: {code: product.code, description: product.description, price: product.price, uom: product.uom}
                    });
                    newWindows[index][category][type] = newWindow;
                }
            });
            this.setState({windows: newWindows});
        }

        let newDoors = this.state.doors.length > 0 ? JSON.parse(JSON.stringify(this.state.doors)) : [];
        this.state.doors.forEach( (door, index) => {
            if (
                category in door && type in door[category] &&
                "count" in door[category][type] &&
                (
                    (!door.isDouble && !(["flushbolt", "catches"].includes(type))) ||
                    door.isDouble
                )
            ) {
                let newProduct = update(door[category][type], {
                    $merge: {
                        code: product.code,
                        uom: product.uom,
                        description: product.description,
                        price: product.price,
                        count: type !== "hinge" ?
                                door[category][type]["count"] :
                                (door.hingesPerDoor && door.hingesPerDoor !== 0) ?
                                door.isDouble ? door.hingesPerDoor * 2 : door.hingesPerDoor : 2
                    }
                });
                newDoors[index][category][type] = newProduct;
            }
            else if (type === "hinge") {
                let hinges = this.getDoorHinges(door);
                if (hinges) {
                    newDoors[index]["hardware"] = update(door["hardware"], {
                        $merge: hinges
                    })
                }
            }
        });
        this.setState({doors: newDoors});
    };

    addPreHangServices = async (object) => {
        const { apolloClient } = this.props;
        const preHangs = [];
        if (
            Lib.deepAttributeExists(['attributes', 'type'], object) !== 'Utility' &&
            (
                Lib.deepAttributeExists(['hinge', 'code'], object.hardware) ||
                (
                    object.doorFunction === 'pocket' //&&
                    // ['Square', 'Round', 'N/A'].includes(Lib.deepAttributeExists(['pocket', 'attributes', 'rosette'], object.hardware))
                )
            )
        ) {
            const attributes = {
                ...(
                    object.doorFunction === 'pocket' ?
                        {
                            prep: `Round Pocket`
                        } : {
                            hingecount:   Number(object.isDouble ? object.hardware['hinge'].count / 2 : object.hardware['hinge'].count),
                            hingestyle:   isNaN(Lib.parseStringNumber(object.hardware['hinge'].attributes.radius)) ?  'Square' : 'Rounded',
                            hingeradius:  Lib.parseStringNumber(object.hardware['hinge'].attributes.radius) || null ,
                            doorwidth:    Lib.parseStringNumber(object.attributes.thickness),
                            doorheight:   Number(object.attributes.height),
                        }
                )
            };
            preHangs.push(attributes)
        }
        // if (
        //     object.hasCutdown &&
        //     object.cutdownHeight >= 3
        // ) {
        //     preHangs.push({
        //         prep: "Custom Hinge Location"
        //     })
        //     preHangs.push({
        //         prep: "Custom Lockset Location"
        //     })
        // }
        if (
            (object.hasCustomHinge && object.customHinges && object.customHinges.filter(v => v).length > 0) || 
            (
                object.hasCutdown && 
                (object.attributes.height - object.cutdownHeight) > 3
            )
        ) {
            preHangs.push({
                prep: "Custom Hinge Location"
            })
        }
        if ( 
            (object.hasCustomLockset && object.customLockset  ) ||
            (
                object.hasCutdown && 
                (object.attributes.height - object.cutdownHeight) > 3
            )
        ) {
            preHangs.push({
                prep: "Custom Lockset Location"
            })
        }
        if ( Lib.deepAttributeExists(['catches', 'attributes', 'style'], object.hardware) === 'Ball' ) {
            preHangs.push({
                prep: "Catches"
            })
        }
        if ( Lib.deepAttributeExists(['flushbolt', 'code'], object.hardware) ) {
            preHangs.push({
                prep: "Flushbolt"
            })
        }
        if (
            object.hasCutdown &&
            object.cutdownLocation === 'inshop' &&
            this.state.specialSpecs.door.type !== 'Utility'
        ) {
            preHangs.push({
                prep: "Cutdown"
            })
        }
        if (preHangs.length > 0) {
            const promises = preHangs.map(attributes => Lib.getLabourItem({
                category: 'Machine',
                type: 'Pre-Hang',
                attributes
            }, apolloClient, true));
            return Promise.all(promises).then(machines => {
                const prehang_service = machines.filter(m => m).map(machine => {
                    const {pricingTiers, ...service} = machine;
                    let isSpecial = false;
                    let count = object.isDouble && Lib.deepAttributeExists(['attributes', 'attributes', 'prep'], service) !== 'Flushbolt' ? 2 : 1
                    if (Lib.deepAttributeExists(['attributes', 'attributes', 'prep'], service) === 'Cutdown' ) {
                        const height = Lib.deepAttributeExists(['attributes', 'height'], object) || 0;
                        if ((height - object.cutdownHeight) > 5 ) {
                            count *= 2;
                        }
                        if (object.cutdownLocation !== Lib.deepAttributeExists(['cutdown'], this.state.specs.door_treatment)) {
                            isSpecial = true;
                        }
                    }
                    return {
                        ...service,
                        price: pricingTiers,
                        count,
                        isSpecial,
                    };
                });
                return {
                    ...object,
                    prehang_service
                };
            })
        } else {
            return object;
        }
    }

    addExtraServices = async (type, object, extra_service = "", extra_service_count = 0) => {
        const { apolloClient } = this.props;
        if (!object) return null;
        const opening_services = [];
        const install_services = [];
        if (this.state.installPricingMode === 'union') {
            // console.log(1, type, object);
            let unionItem = {};
            if (type === 'door' && Lib.deepAttributeExists(['casing', 'attributes', 'width'], object.trim)) {
                unionItem = {
                    type: 'door',
                    casing: Lib.parseStringNumber(Lib.deepAttributeExists(['casing', 'attributes', 'width'], object.trim)) || 0,
                    height: Lib.deepAttributeExists(['attributes', 'height'], object) || 0,
                    ...(object.isBifold && {bifold: true}),
                    ...(object.isDouble && {double: true}),
                }
            } else if (
                type === 'opening' && 
                ['window', 'archway', 'attic', 'caps', 'exterior_door'].includes(object.type) 
            ) {
                // console.log(object)
                const t = object.type === 'caps' ? 'cap' : object.type;
                unionItem = {
                    type: t,
                    casing: Lib.parseStringNumber(Lib.deepAttributeExists(['casing', 'attributes', 'width'], object.trim)) || 0,
                    ...(object.isHigh && {high: true}),
                    ...(object.type === 'window' && object.addBuildout && {buildout: object.openingDepth}),
                    ...(object.type === 'exterior_door' && object.hasCloser && {hasCloser: true}),
                }
            } else if (type === 'trim' &&  object.type === 'baseboard') {
                unionItem = {
                    type: 'baseboard',
                    width: Lib.parseStringNumber(Lib.deepAttributeExists(['attributes', 'width'], object.product)) || 0,
                };
            } else if (type === 'opening' &&  object.type === 'arch_window') {
                unionItem = {
                    type: 'curve',
                    ...(object.isHigh && {high: true}),
                }
            } else if (type === 'opening' &&  object.type === 'stairs') {
                unionItem = {
                    type: 'stairs',
                    attributes: {
                        ...object.attributes,
                        ...(object.addHandrail && {addHandrails: true}),
                    },
                }
            } else if (type === 'shelf') {
                unionItem = {
                    type: 'shelving',
                    material: object.type.charAt(0).toUpperCase() + object.type.slice(1),
                    ...(object.type == 'curve' && {precut: true}),
                }
            }
            if (unionItem.type) {
                const services = await Lib.getUnionServices(this.state.takeoffEditId, unionItem, apolloClient);
                const count = unionItem.type === 'baseboard' ? object.sqft : 1;
                delete object.sqft;
                // console.log(services)
                return {
                    ...object, 
                    install_service: services.map(service => {
                        return {
                            ...service,
                            count,
                            isSpecial: false,
                        }
                    })
                }
            } else return object
        } else {
            // Opening install
            if (type === 'door') {
                if      (object.doorFunction === 'pocket') opening_services.push('pocket_door');
                else if (object.doorFunction === 'bypass') opening_services.push('slider');
                else if (object.doorFunction === 'barn') opening_services.push('barn_door');
                else if (Lib.deepAttributeExists(['door', 'type'], this.state.specialSpecs) === 'Utility') opening_services.push('cold_room_door');
                else if (object.isDouble && object.isBifold) opening_services.push('double_bifold_door');
                else if (object.isDouble) opening_services.push('double_door');
                else if (object.isBifold) opening_services.push('single_bifold_door');
                else    opening_services.push('single_door');
            } else if (type === 'opening') {
                opening_services.push(object.type === 'caps' ? 'wall_capping' : object.type);
                if (object.isHigh) opening_services.push('high_window');
                if (object.addSplit) install_services.push('splitting_charge');
                if (object.continuousHandrail) opening_services.push('continuous_handrail');
                if ('addBuildout' in object && object.addBuildout) opening_services.push('buildout');
            }
    
            // Extra services
            if (type === 'door' && Number(object.attributes['height']) >= 96) {
                install_services.push('high_door');
            }
            if (
                type === 'door'   &&
                object.hasCutdown &&
                object.cutdownLocation === 'onsite' &&
                Lib.deepAttributeExists(['door', 'type'], this.state.specialSpecs) !== 'Utility'
            ) {
                install_services.push('cut_door');
            }
            if (Lib.deepAttributeExists(['architrave', 'code'], object.trim)) {
                install_services.push('architrave');
            }
            if (Lib.deepAttributeExists(['backband', 'code'], object.trim)) {
                install_services.push('backband_installation');
            }
            if (Lib.deepAttributeExists(['sill', 'code'], object.trim)) {
                install_services.push('window_sill');
            }
            if (
                type === 'trim' &&
                Lib.deepAttributeExists(['attributes', object.type === 'crown_moulding' ? 'highCeiling' : 'install'], object)
            ) {
                install_services.push(object.type === 'crown_moulding' ? 'crown_high' : 'shoe_moulding');
            }
            if (type === 'trim' && object.type === 'dap' && extra_service) {
                install_services.push(extra_service)
            }
            if (object.type === 'crown_moulding') {
                install_services.push('crown')
            }
            if (type === 'shelf') {
                install_services.push('wire_install');
            }
            const servicesPromise = install_services.map(async service_type => {
                const attributes = {
                    category: 'Install',
                    type: Lib.humanize(service_type),
                    // type: service_type === "crown_high" ? "Crown High 9 foot ceiling" : Lib.humanize(service_type),
                    ...(service_type === 'crown' && {
                        attributes: {
                            width: Lib.parseStringNumber(Lib.deepAttributeExists(['attributes', 'width'], object.product) || 0)
                        }
                    })
                };
                const installPromise = Lib.getLabourItem(attributes, apolloClient);
                return installPromise.then(install => {
                    if (install) {
                        const {pricingTiers, ...service} = install;
                        let count = extra_service_count ? extra_service_count : service_type === 'backband_installation' && object.backbandSides === 'two_sides' ?
                                        2 : service_type === 'architrave' && object.architraveSides === 'two_sides' ?
                                            2 : service_type === 'wire_shelf' ?
                                                Number(object.quantity) : type === 'trim' ?
                                                object.count : ['cut_door', 'high_door'].includes(service_type) && object.isDouble ?
                                                    2 : 1;
                        let isSpecial = false;
                        if (service_type === 'cut_door' ) {
                            const height = Lib.deepAttributeExists(['attributes', 'height'], object) || 0;
                            if ((height - object.cutdownHeight) > 5 ) {
                                count *= 2;
                            }
                            if (object.cutdownLocation !== Lib.deepAttributeExists(['cutdown'], this.state.specs.door_treatment)) {
                                isSpecial = true;
                            }
                        }
                        return {
                            ...service,
                            price: pricingTiers,
                            count,
                            isSpecial,
                            attributes
                        };
                    }
                });
            });
            return Promise.all(servicesPromise).then(services => {
                return {
                    ...object,
                    ...(services.length > 0 ? {install_service: services} : {}),
                    ...(opening_services.length > 0 ? {opening_service: opening_services} : {})
                }
            });
        }
    }

    updateDoorsJambs = () => {
        const { apolloClient } = this.props;
        let newDoors = JSON.parse(JSON.stringify(this.state.doors));
        this.state.doors.forEach( (door, index) => {
            if ("jamb" in door.trim && Object.keys(door.trim.jamb).length > 0) {
                let attributes = {
                    category: "Trim",
                    type: "Jamb",
                    supplier:   this.getSpecsAttributeValue('supplier', 'trim', 'jamb'),
                    style:      this.getSpecsAttributeValue('style', 'trim', 'jamb'),
                    species:    this.getSpecsAttributeValue('species', 'trim', 'jamb'),
                    thickness:  this.getSpecsAttributeValue('thickness', 'trim', 'jamb'),
                    width:      door.jambWidth,
                };
                if (attributes.supplier.toLowerCase() === "commodity") {
                    attributes = update(attributes,{
                        $merge: {
                            finish: this.getSpecsAttributeValue('finish', 'trim', 'jamb'),
                            height: this.getSpecsAttributeValue('height', 'trim', 'jamb')
                        }
                    })
                }
                let jambPromise = Lib.getAnyProduct(attributes, apolloClient);
                jambPromise.then(jamb => {
                    if (jamb) {
                        let totalJamb = 0;
                        if (jamb.uom.toLowerCase() === "pieces") {
                            totalJamb = door.isDouble ? 3 : 2.5
                        }
                        else {
                            totalJamb = Lib.calculateTrims('jamb', door)
                        }
                        let jambObj = {
                            description: jamb.description,
                            price: jamb.pricingTiers,
                            code: jamb.code,
                            count: totalJamb, // TODO: update when double door or something
                            uom: jamb.uom,
                            type: "jamb",
                            isSpecial: false
                        };
                        newDoors[index].trim = newDoors[index].trim || {}
                        newDoors[index].trim["jamb"] = jambObj
                    }
                    else {
                        newDoors[index].trim.jamb = {}
                    }
                })
            }
        });
        this.setState({
            doors: newDoors,
        })
    };

    countShelving = (type, quantity, width, shelfLength, productlength) => {
        if (type === 'organizer') return 1;
        return type === "wood" ?
        Number(quantity) === Math.ceil(quantity) &&
        (productlength / shelfLength) > 2 ?
            Math.ceil(Number(quantity) * (shelfLength / productlength )) :
            Number(quantity) :
                Number(quantity * width / 12).toFixed(2);
    }

    createShelf = async (product) => {
        const { apolloClient } = this.props;
        const {id, ...restProduct} = product;
        const item = {
            ...restProduct,
            
        }
        return Lib.saveMeasureShelf({id: this.state.takeoffEditId, index: id, item: restProduct}, apolloClient);
    }
    
    addMeasureShelving = async () => {
        const { apolloClient } = this.props;
        const shelf = this.state.measures.custom.shelving;
        // console.log({shelf});
        let attributes = {
            category: "Shelving",
            type: shelf.type,
            ...(shelf.type === 'organizer' && {
                ...this.state.specialSpecs.shelving.organizer
            }),
            ...(shelf.type === 'wire' && {mode: this.state.specialSpecs.shelving[shelf.type].mode}),
            ...(shelf.type !== 'organizer' && {depth: shelf.depth}),
            shelflength: shelf.type === "wood" ? Math.ceil(shelf.width) : 0,
        };
        let product = {};
        if (!['accessories', 'organizer'].includes(shelf.type)) {
            const shelving = await Lib.getAnyProduct( attributes, apolloClient, ["shelflength"] );
            let count = this.countShelving(
                shelf.type,
                shelf.quantity,
                shelf.width,
                attributes.shelflength,
                shelving.shelflength,
            );
            product = {
                attributes:{
                    ...attributes,
                    shelflength: undefined
                },
                code: shelving.code,
                description: shelving.description,
                price: shelving.pricingTiers,
                uom: shelving.uom,
                count,
                isSpecial: shelf.type === 'wire' && this.isSpecialSpecs('shelving', 'wire')
            }
        }

        let newShelving = {
            id:                     shelf.id || null,
            itemIndex:              shelf.itemIndex,
            unitNumber:             shelf.unitNumber,
            title:                  shelf.title,
            additionalItem:         shelf.additionalItem,
            type:                   shelf.type,
            addedInstall:           shelf.addedInstall,
            quantity:               shelf.quantity,
            ...(
                ['wood', 'wire'].includes(shelf.type) && {
                    depth:                  shelf.depth,
                    width:                  shelf.width,
                    additional_brackets:    shelf.additional_brackets,
                    additional_rods:        shelf.additional_rods,
                }
            ),
            ...(shelf.type === "organizer" && { 
                dimensions: shelf.dimensions,
                miscellaneous: shelf.miscellaneous,
                wallDesigns: shelf.wallDesigns,
            }),
            notes:                  shelf.notes,
            images:                 shelf.images,
            createdAt:              shelf.createdAt,
            updatedAt:              shelf.updatedAt,
            product,
            hardware: {},
            trim: {},
            accessories: [],
            install_service: [],
        };

        if (shelf.type === "wood") {
            if (Lib.deepAttributeExists(['products', 'cleat', 'code'], this.state.specialSpecs)) {
                const cleat =  this.state.specialSpecs.products.cleat;
                const bracketSupport = shelf.width > 48 ? Math.ceil(newShelving.quantity) * Math.floor(newShelving.width / 36) : 0;
                const count = Math.ceil(Number(newShelving.quantity) * ((Number(newShelving.width) + (Number(attributes.depth) * 2) + 4)) / 12);
                let cleatObj = {
                    cleat: {
                        code: cleat.code,
                        description: cleat.description,
                        price: cleat.price,
                        uom: cleat.uom,
                        count: count + bracketSupport,
                        attributes: cleat.attributes || {},
                        isSpecial: this.isSpecialSpecs('shelving', 'cleat')
                    }
                };
                newShelving = update(newShelving, {
                    trim: {$merge: cleatObj}
                })
            }
            if (shelf.rod_finish) {
                // get Rod
                let rodAttributes = {
                    category: "Hardware",
                    type: "Rod",
                    width: shelf.width,
                    finish: shelf.rod_finish
                };
                const rod = await Lib.getAnyProduct(rodAttributes, apolloClient);
                if (rod) {
                    const rodObj = {
                        rod: {
                            code: rod.code,
                            description: rod.description,
                            price: rod.pricingTiers,
                            uom: rod.uom,
                            count: Math.ceil(newShelving.quantity) + parseInt(shelf.additional_rods || 0),
                            attributes: {
                                finish: shelf.rod_finish
                            },
                            isSpecial: false
                        }
                    };
                    newShelving = update(newShelving, {
                        hardware: {$merge: rodObj}
                    })
                }
            }
            if ( newShelving.width > 48 || shelf.additional_brackets) {
                // Add bracket
                const bracketAttributes = {
                    category: "Hardware",
                    type: "Shelf Bracket",
                    hook: shelf.rod_finish ? "1" : "0",
                };
                const bracket = await Lib.getAnyProduct(bracketAttributes, apolloClient);
                const count = newShelving.width > 48 ? Math.ceil(newShelving.quantity) * Math.floor(newShelving.width / 36) + parseInt(shelf.additional_brackets || 0) : parseInt(shelf.additional_brackets || 0)
                if (bracket) {
                    const bracketObj = {
                        bracket: {
                            isSpecial: false,
                            attributes: {},
                            code: bracket.code,
                            description: bracket.description,
                            price: bracket.pricingTiers,
                            uom: bracket.uom,
                            count: count
                        }
                    };
                    newShelving = update(newShelving, {
                        hardware: {$merge: bracketObj}
                    })
                }
            }
            return newShelving

        } else if (['organizer', 'accessories'].includes(shelf.type)) {
            const design = Lib.deepAttributeExists(['organizer', 'design'], this.state.specialSpecs.shelving);
            const size = Lib.deepAttributeExists(['organizer', 'size'], this.state.specialSpecs.shelving);
            if (shelf.hasInstall) {
                const wallsDesigns = Object.keys(shelf.wallDesigns || {}).reduce(
                    (acc, key) => {
                        shelf.wallDesigns[key]?.designs?.forEach(d => {
                            acc[d.design] = ~~acc[d.design] + 1
                        });
                        return acc;
                    }
                , {});
                const wallDesignsPromises = Object.keys(wallsDesigns).map(design => {
                    const install_attributes = {
                        category: 'Install',
                        type: 'Organizer',
                        attributes: {
                            design
                        }
                    }
                    return Lib.getLabourItem(install_attributes, apolloClient, true, {design});
                });
                const organiserInstalls = await Promise.all(wallDesignsPromises);
                organiserInstalls?.forEach(install => {
                    if (install) {
                        newShelving.install_service.push({
                            isSpecial: false,
                            attributes: install.attributes,
                            code: install.code,
                            description: install.description,
                            price: install.pricingTiers,
                            uom: 'Each',
                            count: wallsDesigns[install.design]
                        })
                    }
                });
            }
            if (shelf.hasCallout) {
                const install_attributes = {
                    category: 'Install',
                    type: 'Call out charge'
                }
                const install = await Lib.getLabourItem(install_attributes, apolloClient);
                if (install) {
                    newShelving.install_service.push({
                        isSpecial: false,
                        attributes: install_attributes,
                        code: install.code,
                        description: install.description,
                        price: install.pricingTiers,
                        uom: 'Each',
                        count: 1
                    })
                }
            }
            if (shelf.hasAccessoryInstall && shelf.accessories.length > 0) {
                const install_attributes = {
                    category: 'Install',
                    type: 'Organizer Accessories'
                }
                const install = await Lib.getLabourItem(install_attributes, apolloClient);
                if (install) {
                    newShelving.install_service.push({
                        isSpecial: false,
                        attributes: install_attributes,
                        code: install.code,
                        description: install.description,
                        price: install.pricingTiers,
                        uom: 'Each',
                        count: shelf.accessories.reduce((acc, a) => {return acc + Number(a.quantity)}, 0) || 1,
                    })
                }
            }
            
            const organizerPromises = [];
            // if (size) {
            //     const attributes = {
            //         ...this.state.specialSpecs.shelving.organizer,
            //         category: "Shelving",
            //         type: "Organizer",
            //     };
            //     organizerPromises.push(
            //         Lib.getAnyProduct(attributes, apolloClient, [], {
            //             wall: 'wall1',
            //             dDesign: design,
            //         })
            //     );
            // }
            const joinersCount = Object.keys(shelf.wallDesigns || {}).reduce((acc, key) => shelf.wallDesigns[key].hasJoiner ? acc + 1 : acc, 0);
            if (joinersCount > 0 && shelf.layout && shelf.layout !== 'reach_in') {
                // fetch joiner if layout is set
                organizerPromises.push(Lib.getAnyProduct({
                    category: "Shelving",
                    type: "Joiner",
                }, apolloClient));
            }
            Object.keys(shelf.wallDesigns || {}).forEach( key => {
                shelf.wallDesigns[key]?.designs.forEach( async (design, i) => {
                    const attributes = {
                        ...this.state.specialSpecs.shelving.organizer,
                        category: "Shelving",
                        type: "Organizer",
                        design: design.design,
                        size: design.size,
                    };
                    organizerPromises.push(
                        Lib.getAnyProduct(attributes, apolloClient, [], {
                            wall: key,
                            dWidth: design.width,
                            dDesign: design.design,
                            dSize: design.size,
                        })
                    );
                })
            });
            const accessoriesPromises = shelf.accessories.map(accessory => Lib.getAnyProduct({
                ...accessory.specs,
                category: "Shelving",
                type: "Organizer Accessories",
            }, apolloClient, [], {aQuantity: accessory.quantity}));
            return Promise.all([
                ...organizerPromises, 
                ...accessoriesPromises
            ]).then(values => {
                // console.log({values});
                let wallDesigns = {}, accessories = [], joiner = {};
                for (const value of values) {
                    const { wall, dWidth, dDesign, dSize, pricingTiers, code, aQuantity, description, ...rest} = value;
                    if (value?.type === 'Organizer') {
                        if (!wallDesigns[wall]) {
                            wallDesigns[wall] = {
                                hasJoiner: shelf.wallDesigns?.[wall]?.hasJoiner,
                                designs: []
                            };
                        }
                        wallDesigns[wall].designs.push({
                            width: dWidth,
                            design: dDesign,
                            size: dSize,
                            product: {
                                description,
                                code,
                                count: 1,
                                isSpecial: false,
                                price: pricingTiers,
                                attributes: { ...rest }
                            },
                        });
                    } else if (value?.type === "Joiner") {
                        joiner = {
                            isSpecial: false,
                            attributes: { ...rest },
                            code: code,
                            description: description,
                            price: pricingTiers,
                            uom: 'Each',
                            count: joinersCount
                        };
                    } else if (value?.type === "Organizer Accessories") {
                        accessories.push({
                            isSpecial: false,
                            attributes: { ...rest },
                            code: code,
                            description: description,
                            price: pricingTiers,
                            uom: 'Each',
                            count: aQuantity
                        })
                    
                    }
                }
                return {
                    ...newShelving,
                    ...(shelf.layout && {layout: shelf.layout}),
                    hardware: {
                        ...newShelving.hardware,
                        joiner,
                    },
                    wallDesigns,
                    accessories
                }
            })
        } else {
            const shelfWServices = await this.addExtraServices('shelf', newShelving)
            return shelfWServices
        }
    };

    getDoorTrims = (door) => {
        const result = {};
        // Casing & Doorstops
        if (Lib.deepAttributeExists(['casing', 'code'], this.state.specialSpecs.products)) {
            const isMill = this.state.specialSpecs.trim.casing.supplier === 'Mill';
            const totalCasing   = Lib.calculateTrims(
                'casing',
                door,
                !isMill ? Lib.deepAttributeExists(['casing', 'length'], this.state.specialSpecs.trim) : 'none'
            );
            result.casing = this.state.specialSpecs.products.casing ? {
                ...this.state.specialSpecs.products.casing,
                count: totalCasing,
                type: "casing",
                isSpecial: this.isSpecialSpecs('trim', 'casing')
            } : {};
        }
        if (Lib.deepAttributeExists(['doorstop', 'code'], this.state.specialSpecs.products)) {
            const totalDoorStop = Lib.calculateTrims('doorstop', door);
            result.doorstop = {
                ...this.state.specialSpecs.products.doorstop,
                count: totalDoorStop,
                type: "doorstop",
                isSpecial: this.isSpecialSpecs('trim', 'doorstop')
            }
        }
        return result;
    };

    addDoorJambs = async (door) => {
        return new Promise( (resolve) => {
            const materialType = door.materialType;
            if (
                materialType &&
                Lib.deepAttributeExists([materialType, 'code'], this.state.specialSpecs.products)
            ) {
                const jamb = this.state.specialSpecs.products[materialType];
                let totalJamb = 0;
                if (/jamb/.test(materialType) && jamb.uom.toLowerCase() === "pieces") {
                    totalJamb = door.isDouble && !door.isHalfTrim ?
                        3 : door.isDouble && door.isHalfTrim ?
                            1.5 : !door.isDouble && !door.isHalfTrim ?
                                2.5 : 1.5;
                } else if (materialType=== 'flat_stock') {
                    const doorPerimeter = Lib.calculateTrims('jamb', door)
                    totalJamb = Math.ceil(door.quantity * doorPerimeter);
                } else if (materialType=== 'sheet') {
                    totalJamb = door.quantity || 0;
                } else {
                    totalJamb = Lib.calculateTrims('jamb', door)
                }
                const doorWJambs = {
                    ...door,
                    trim:{
                        ...door.trim,
                        [/jamb/.test(materialType) ? 'jamb' : materialType]: {
                            ...this.state.specialSpecs.products[materialType],
                            isSpecial: this.isSpecialSpecs('trim', materialType),
                            count: totalJamb
                        }
                    }
                }
                resolve(doorWJambs);
            } else {
                resolve(door);
            }
        });
    };

    addDoorHardware = async (door, hardware) => {
        // const { apolloClient } = this.props;
        let doorFunction = door.doorFunction;
        if (
            door.handleType &&
            Lib.isEmptyOrNullObject(this.state.specialSpecs.hardware[door.handleType])
        ) {
            door.handleType = "";
        }
        return new Promise( (resolve) => {
            if (["swing", "bifold"].includes(doorFunction)) {
                this.addDoorstoppers(door)
                    .then(doorWStoppers => {
                        this.addDoorHandleAndLatch( doorWStoppers)
                            .then(newDoor => {
                                resolve(newDoor);
                            })
                    })
            }
            else if (
                doorFunction === "pocket" &&
                "pocket" in hardware &&
                door.pocket_mode
            ) {
                this.addPocketHardware(door, hardware["pocket"])
                    .then(doorWPocket => {
                        resolve(doorWPocket);
                    })
            }
            else if (
                doorFunction === "barn" &&
                "barn_door_track" in hardware
            ) {
                this.addBarnHardware(door)
                    .then(doorWBarn => {
                        resolve(doorWBarn);
                    })
            }
            else if (
                doorFunction === "bypass" &&
                ("bipass_door_track" in hardware || "finger_pull" in hardware)
            ) {
                this.addBypassHardware(door)
                    .then(doorWBypass => {
                        resolve(doorWBypass);
                    })
            }
            else {
                resolve(door);
            }
        })
    }

    addBypassHardware = async (door) => {
        const { apolloClient } = this.props;
        const fingerPull = this.state.specialSpecs.products.finger_pull || {};
        const fingerPullProduct = {
            ...fingerPull,
            count: 2,
            isSpecial: this.isSpecialSpecs('hardware', 'finger_pull')
        };
        return new Promise( (resolve) => {
            if (
                door.isDouble &&
                !Lib.isEmptyOrNullObject(this.state.specialSpecs.hardware.bipass_door_track)
            ){
                let attributes = {
                    category: "Hardware",
                    type: "bipass_door_track",
                    brand: this.state.specialSpecs.hardware.bipass_door_track.brand,
                    style: this.state.specialSpecs.hardware.bipass_door_track.style,
                    tracklength: door.bipassTrackLength
                }
                const trackPromise = Lib.getAnyProduct(attributes, apolloClient);
                return trackPromise.then( product => {
                    if (product) {
                        delete attributes.tracklength;
                        let trackProduct = {
                            attributes: attributes,
                            code: product.code,
                            uom: product.uom,
                            count: 1,
                            description: product.description,
                            price: product.pricingTiers,
                            isSpecial: this.isSpecialSpecs('hardware', 'bipass_door_track')
                        };

                        resolve(update (door, {
                            hardware: {
                                $merge: {
                                    bipass_door_track: trackProduct,
                                    finger_pull: fingerPullProduct
                                }
                            }
                        }));
                    }
                })
            } else {
                resolve(update (door, {
                    hardware: {
                        $merge: {
                            finger_pull: fingerPullProduct
                        }
                    }
                }));
            }
        })
    };

    addPocketHardware = async (door, pocketSpecs) => {
        const { apolloClient } = this.props;
        return new Promise(  (resolve) => {
            let pocketAttributes = {
                category:       "Hardware",
                type:           "Pocket",
                brand:          "brand"     in pocketSpecs ? pocketSpecs.brand      : "",
                finish:         "finish"    in pocketSpecs ? pocketSpecs.finish     : "",
                style:          "style"     in pocketSpecs ? pocketSpecs.style      : "",
                rosette:        "rosette"   in pocketSpecs ? pocketSpecs.rosette    : "",
                mode:           door.pocket_mode,
            };

            Lib.getAnyProduct(pocketAttributes, apolloClient)
                .then(pocket => {
                    delete pocketAttributes.mode;
                    let pocketProduct = {
                        attributes: pocketAttributes,
                        code: pocket.code,
                        uom: pocket.uom,
                        count: door.isDouble ? 2 : 1,
                        description: pocket.description,
                        price: pocket.pricingTiers,
                        isSpecial: this.isSpecialSpecs('hardware', 'pocket')
                    };
                    let doorWPocket = update (door, {
                        hardware: {
                            $merge: {pocket: pocketProduct}
                        }
                    });
                    resolve(doorWPocket);
                })
        });
    };

    addBarnHardware = async (door) => {
        return new Promise( (resolve) => {
            let barnProduct = {
                ...this.state.specialSpecs.products.barn_door_track,
                count: door.isDouble ? 2 : 1,
                isSpecial: this.isSpecialSpecs('hardware', 'barn_door_track')
            };
            let doorWBarn = update (door, {
                hardware: {
                    $merge: {"barn_door_track": barnProduct}
                }
            });
            resolve(doorWBarn);
        });
    };

    getDoorBackband = async (door) => {
        return new Promise( (resolve) => {
            if (
                door.addBackband &&
                Lib.deepAttributeExists(['backband', 'code'], this.state.specialSpecs.products)
            ) {
                const backband = this.state.specialSpecs.products.backband;
                const casingCount = door.trim.casing.count || 0;
                let totalBackband = door.backbandSides === 'two_sides' ? casingCount : Math.ceil(casingCount / 2);
                backband.count = totalBackband;
                backband.isSpecial = this.isSpecialSpecs('trim', 'backband');
                const doorWBackband = {
                    ...door,
                    trim:{
                        ...door.trim,
                        backband,
                    },
                };
                resolve(doorWBackband);
            } else {
                resolve(door);
            }
        });
    };

    getDoorArchitrave = async (door) => {
        return new Promise(  (resolve) => {
            if (
                door.addArchitrave &&
                Lib.deepAttributeExists(['architrave', 'code'], this.state.specialSpecs.products)
            ) {
                const casingCount       = Lib.deepAttributeExists(['casing', 'count'], door.trim);
                const casingWidth       = Lib.deepAttributeExists(['casing', 'attributes', 'width'], this.state.specialSpecs.products) || 0;
                const architraveWidth   = (Number(door.width) + (Lib.parseStringNumber(casingWidth) * 2) + 6) / 12;
                const architrave        = this.state.specialSpecs.products.architrave;
                //let totalArchitrave = Math.ceil(door.architraveSides === 'two_sides' ? 2*Number(door.architraveWidth) / 12 : Number(door.architraveWidth) /12);
                architrave.count        = Math.ceil(architraveWidth) * (door.architraveSides === 'two_sides' ? 2 : 1)
                architrave.isSpecial    = this.isSpecialSpecs('trim', 'architrave');
                const newCasingCount    = casingCount !== 0 ? door.architraveSides === 'two_sides' ? casingCount - 7 : casingCount - 4 : 0;
                const doorWArchitrave   = {
                    ...door,
                    trim:{
                        ...door.trim,
                        architrave,
                        ...(
                            'casing' in door.trim &&
                            door.trim.casing ?
                                {
                                    casing: {
                                        ...door.trim.casing,
                                        count: newCasingCount
                                    }
                                } : {}
                        )
                    },
                }
                resolve(doorWArchitrave);
            } else {
                resolve(door);
            }
        });
    };

    getDoorBurlap = async (door, time = 1) => {
        return new Promise( (resolve) => {
            if (
                door.isDouble &&
                door.latching === 'flushbolt' &&
                Lib.deepAttributeExists(['code'], this.state.specialSpecs.products.burlap)
            ) {
                const burlap = this.state.specialSpecs.products.burlap;
                burlap.count = 8 * time;
                burlap.isSpecial = this.isSpecialSpecs('trim', 'burlap');
                const doorWBurlap = {
                    ...door,
                    trim:{
                        ...door.trim,
                        burlap
                    }
                }
                resolve(doorWBurlap);
            } else {
                resolve(door);
            }
        });
    };

    getDoorHinges = async (door) => {
        if (!door.isBifold) {
            // Door hinges
            if (
                !Lib.deepAttributeExists(['hinge', 'code'], this.state.specialSpecs.products) ||
                door.hingesPerDoor === "" ||
                door.hingesPerDoor === null ||
                door.isBifold ||
                door.doorFunction !== "swing"
            ) {
                return door;
            }
            let noOfHinges = door.hingesPerDoor;
            const isSpecial = this.isSpecialSpecs('hardware', 'hinge');
            return update(door, {
                hardware: {
                    $set: {
                        hinge: {
                            code: this.state.specialSpecs.products.hinge.code,
                            uom: this.state.specialSpecs.products.hinge.uom,
                            description: this.state.specialSpecs.products.hinge.description,
                            price: this.state.specialSpecs.products.hinge.price,
                            attributes: this.state.specialSpecs.products.hinge.attributes,
                            count: door.isDouble ? noOfHinges * 2 : noOfHinges,
                            isSpecial
                        }
                    }
                }
            });
        }
        else {
            return door;
        }
    };

    addDoorstoppers = async (door) => {
        const { apolloClient } = this.props;
        return new Promise( (resolve) => {
            const isSpecial = this.isSpecialSpecs('hardware', 'doorstopper');
            if (
                !door.isDouble &&
                !Lib.isEmptyOrNullObject(this.state.specialSpecs.hardware.doorstopper) &&
                door.doorstopper_mode
            ) {
                let attributes = {
                    category: "Hardware",
                    type: "Doorstopper",
                    finish: this.state.specialSpecs.hardware.doorstopper.finish,
                    mode: door.doorstopper_mode
                }
                const doorstopperPromise = Lib.getAnyProduct(attributes, apolloClient);
                return doorstopperPromise.then( product => {
                    if (product) {
                        delete attributes.mode;
                        let doorstopperProduct = {
                            attributes: attributes,
                            code: product.code,
                            uom: product.uom,
                            count: 1,
                            description: product.description,
                            price: product.pricingTiers,
                            isSpecial
                        };

                        resolve(update (door, {
                            hardware: {
                                $merge: {"doorstopper": doorstopperProduct}
                            }
                        }));
                    }
                })
            } else if (
                door.isDouble &&
                !Lib.isEmptyOrNullObject(this.state.specialSpecs.hardware.doorstopper) &&
                (door[`doorstopper_mode_left`] || door[`doorstopper_mode_right`])
            ) {
                let doorWStoppers = door;
                const stopperPromises = ['left', 'right'].map( side => new Promise( resolve => {
                    if (doorWStoppers[`doorstopper_mode_${side}`]) {
                        let attributes = {
                            category: "Hardware",
                            type: "Doorstopper",
                            finish: this.state.specialSpecs.hardware.doorstopper.finish,
                            mode: doorWStoppers[`doorstopper_mode_${side}`]
                        };
                        Lib.getAnyProduct(attributes, apolloClient)
                            .then(product => {
                                if (product) {
                                    delete attributes.mode;
                                    let doorstopperProduct = {
                                        attributes: attributes,
                                        code: product.code,
                                        uom: product.uom,
                                        count: 1,
                                        description: product.description,
                                        price: product.pricingTiers,
                                        isSpecial
                                    };
                                    resolve({side, doorstopperProduct})
                                }
                            })
                    }
                }))
                Promise.all(stopperPromises).then( values =>{
                    values.forEach( ({side, doorstopperProduct}) => {
                        doorWStoppers = update (doorWStoppers, {
                            hardware: {
                                ...( 'double' in doorWStoppers.hardware ? {
                                    double:{
                                        ...( side in doorWStoppers.hardware.double ?
                                                {[side] : {$merge: {"doorstopper": doorstopperProduct}}} :
                                                {$merge: {[side]: {"doorstopper": doorstopperProduct}}}
                                        )
                                    }
                                } : {
                                    $merge: {
                                        double:{
                                            [side] : {"doorstopper": doorstopperProduct }
                                        }
                                    }
                                })
                            }
                        })
                    })
                    resolve(doorWStoppers);
                })
                
            } else {
                resolve(door);
            }
        });
    };

    addDoorHandleAndLatch = async (door) => {
        const { apolloClient } = this.props;
        return new Promise( (resolve) => {
            let doorHandleLatch = door;
            let type = doorHandleLatch.handleType;
            let specs = {}
            if (
                type &&
                type !== 'bifold_knob' &&
                this.isSpecSet ("brand", "hardware", type, 'specialSpecs') &&
                this.isSpecSet ("style", "hardware", type, 'specialSpecs') &&
                this.isSpecSet ("finish", "hardware", type, 'specialSpecs')
            ) {
                specs = {
                    brand:  this.state.specialSpecs.hardware[type].brand || '',
                    style:  this.state.specialSpecs.hardware[type].style || '',
                    finish: this.state.specialSpecs.hardware[type].finish || '',
                    rosette: this.state.specialSpecs.hardware[type].rosette || '',
                    firerated: this.state.specialSpecs.hardware[type].firerated || '',
                };
            }
            if ( // Single Door
                ["swing", "bifold"].includes(this.state.measures.door.doorFunction) &&
                type &&
                type !== 'bifold_knob' &&
                !doorHandleLatch.isDouble &&
                type in this.state.specialSpecs.hardware &&
                this.state.specialSpecs.hardware[type] &&
                Object.keys(this.state.specialSpecs.hardware[type]).length > 0 &&
                this.state.specialSpecs.hardware[type].brand  &&
                this.state.specialSpecs.hardware[type].style  &&
                this.state.specialSpecs.hardware[type].finish &&
                doorHandleLatch.handleType &&
                doorHandleLatch.handleMode &&
                Object.keys(specs).length > 0
            ) {
                let doorAttributes = {
                    handleDirection: doorHandleLatch.handleDirection,
                    handleType : doorHandleLatch.handleType,
                    handleMode: doorHandleLatch.handleMode === "1" ? "Dummy" : doorHandleLatch.handleMode,
                    isReverse: doorHandleLatch.isReverse,
                };
                const customer = this.state[this.state.customerType || 'customer'];
                
                Lib.getHandleProduct(customer, doorAttributes, specs, apolloClient)
                .then(handle => {
                    if (handle) {
                        let handleWType = {
                            [doorHandleLatch.handleType] : {
                                attributes: specs,
                                description: handle.description,
                                uom: handle.uom,
                                price: handle.pricingTiers,
                                code: handle.code,
                                count: 1,  // TODO: update when double door or something
                                type: doorHandleLatch.handleType,
                                isSpecial: this.isSpecialSpecs('hardware', type)
                            }
                        };
                        doorHandleLatch = update(doorHandleLatch, {
                            hardware: {
                                "$merge" : handleWType
                            }
                        });
                        resolve(doorHandleLatch);
                    } else {
                        resolve(door)
                    }
                })
            } else if ( // Double Swing Door
                door.doorFunction === "swing" &&
                door.isDouble
            ) {
                // Double door latch
                let doorLatch = doorHandleLatch.latching;
                if (
                    doorLatch &&
                    Lib.deepAttributeExists([doorLatch, 'code'], this.state.specialSpecs.products)
                ) {
                    let latchProduct = {
                        count: doorLatch === 'catches' ? 2 : 1,
                        type: doorLatch,
                    };
                    latchProduct = update (latchProduct,{
                        "$merge" : {
                            ...this.state.specialSpecs.products[doorLatch],
                            isSpecial: this.isSpecialSpecs('hardware', doorLatch)
                        }
                    })
                    doorHandleLatch = update (doorHandleLatch, {
                        hardware: {
                            "$merge" : {
                                [doorLatch] : latchProduct,
                            },
                        }
                    });
                    if (doorHandleLatch.handleType) {
                        this.addDoubleDoorHandles(specs, doorHandleLatch)
                            .then(result => {
                                resolve(result);
                            })
                    } else {
                        resolve(doorHandleLatch)
                    }
                } else {resolve(door)}
            } else if (
                type === 'bifold_knob' &&
                Lib.deepAttributeExists(['bifold_knob', 'code'], this.state.specialSpecs.products)
            ) { //  Bifold knob door
                const doorWithBifoldKnob = {
                    ...doorHandleLatch,
                    hardware: {
                        ...doorHandleLatch.hardware,
                        bifold_knob: {
                            ...this.state.specialSpecs.products.bifold_knob,
                            count: doorHandleLatch.isDouble ? 2 : 1,
                            isSpecial: this.isSpecialSpecs('hardware', 'bifold_knob')
                        }
                    }
                };
                resolve(doorWithBifoldKnob)
            } else if ( // Double Bifold door
                doorHandleLatch.doorFunction === "bifold" &&
                type !== 'bifold_knob' &&
                doorHandleLatch.isDouble
            ) {
                if (Object.keys(specs).length > 0) {
                    this.addDoubleDoorHandles(specs, doorHandleLatch)
                        .then(result => {
                            resolve(result);
                        })
                }
            }
            else {
                resolve(door);
            }
        });
    };

    addDoubleDoorHandles = async (specs, door) => {
        const { apolloClient } = this.props;
        let newDoor = door;
        for (const side of ["left", "right"]) {
            await new Promise( (resolve ) => {
                let doorAttributes = {
                    handleDirection: side,
                    handleType : newDoor.handleType,
                    handleMode: newDoor[side+"HandleMode"] in ["0", "1", "2"] ? "Dummy" : newDoor[side+"HandleMode"],
                    isReverse: false,
                };
                if ( !['', '0'].includes(newDoor[side+"HandleMode"]) ) {
                    const customer = this.state[this.state.customerType || 'customer'];
                    Lib.getHandleProduct(customer, doorAttributes, specs, apolloClient)
                    .then(handle => {
                        if (handle) {
                            let handleWType = {
                                attributes: specs,
                                description: handle.description,
                                price: handle.pricingTiers,
                                code: handle.code,
                                uom: handle.uom,
                                count: newDoor.handleType === "knob" && newDoor[side+"HandleMode"] === "2" ? 2 : 1,
                                type: newDoor.handleType,
                                isSpecial: this.isSpecialSpecs('hardware', newDoor.handleType)
                            };
                            newDoor = update(newDoor, {
                                hardware: {
                                    ...('double' in newDoor.hardware ? {
                                        double : {
                                            ...( side in newDoor.hardware.double ?
                                                {
                                                    [side] : {
                                                        ...(newDoor.handleType in newDoor.hardware.double[side] ?
                                                            {[newDoor.handleType]: {$push: [handleWType]}} :
                                                            {
                                                                $merge: {
                                                                    [newDoor.handleType]: [handleWType]
                                                                }
                                                            }
                                                        )
                                                    }
                                                } :
                                                {
                                                    $merge: {
                                                        [side]: {
                                                            [newDoor.handleType]: [handleWType]
                                                        }
                                                    }
                                                }
                                            ),
                                        }
                                    } : {
                                        $merge: {
                                            double : {
                                                [side]: {
                                                    [newDoor.handleType]: [handleWType]
                                                }
                                            }
                                        }
                                    })
                                }
                            });
                            if (door.handleType !== "knob" && door[side+"HandleMode"] === "2") {
                                doorAttributes["handleDirection"] = side === "right" ? "left" : "right";
                                const customer = this.state[this.state.customerType || 'customer'];
                                Lib.getHandleProduct(customer, doorAttributes, specs, apolloClient)
                                    .then(handle => {
                                        if (handle) {
                                            let handleWType2 = {
                                                attributes: specs,
                                                description: handle.description,
                                                price: handle.pricingTiers,
                                                code: handle.code,
                                                uom: handle.uom,
                                                count: 1, // TODO: update when double door or something
                                                type: door.handleType,
                                                isSpecial: this.isSpecialSpecs('hardware', door.handleType)
                                            };
                                            newDoor = update(newDoor, {
                                                hardware: {
                                                    double : {
                                                        [side] : {
                                                            [door.handleType]: {$push: [handleWType2]}
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                        resolve()
                                    })
                            } else {
                                resolve()
                            }
                        } else { resolve() }
                    })
                } else { resolve() }
            });
        }
        return newDoor;
    };

    addCaps = async () => {
        return new Promise((resolve) => {
            const customMeasure = this.state.measures.custom;
            const w = Number(customMeasure.window.width) / 12;
            const l = Number(customMeasure.window.height) / 12;
            const layout = customMeasure.window.layout;
            let cap = {
                id:             customMeasure.window.id || null,
                itemIndex:      customMeasure.window.itemIndex,
                unitNumber:     customMeasure.window.unitNumber,
                title:          customMeasure.window.title,
                additionalItem: customMeasure.window.additionalItem,
                type:           customMeasure.window.type,
                width:          (w * 12) || 0,
                height:         (l * 12) || 0,
                quantity:       customMeasure.window.quantity,
                materialType:   customMeasure.window.materialType,
                notes:          customMeasure.window.notes,
                images:         customMeasure.window.images,
                hasExtraService:customMeasure.window.hasExtraService,
                extraService:   customMeasure.window.extraService,
                layout:         layout,
                trim:           {},
                sheet:          {},
                createdAt    :  customMeasure.window.createdAt,
                updatedAt    :  customMeasure.window.updatedAt,
            };

            let casing = {  };
            if ("casing" in this.state.specialSpecs.products) {
                casing = update( casing, {
                    $merge: {
                        ...this.state.specialSpecs.products.casing,
                        isSpecial: this.isSpecialSpecs('trim', 'casing')
                    }
                })
            }
            casing.count = eval(layout) || 0;
            cap = update (cap, {
                trim: {
                    "$merge": {casing}
                }
            });
            if (
                cap.materialType === 'sheet' &&
                Lib.deepAttributeExists(['sheet', 'code'], this.state.specialSpecs.products)
            ) {
                const product = this.state.specialSpecs.products.sheet;
                const sheet = {
                    code:           product.code,
                    description:    product.description,
                    price:          product.price,
                    uom:            product.uom,
                    count:          customMeasure.window.quantity || 0,
                    attributes:     {
                        category: 'Sheet',
                        ...this.state.specialSpecs.trim.sheet
                    },
                    isSpecial: this.isSpecialSpecs('trim', 'sheet')
                };
                cap = update(cap, {
                    trim: {
                        "$merge": {sheet}
                    }
                });
                resolve(cap)
            } else if (
                cap.materialType === 'flat_stock' &&
                Lib.deepAttributeExists(['flat_stock', 'code'], this.state.specialSpecs.products)
            ) {
                const product = this.state.specialSpecs.products.flat_stock;
                const flat_stock = {
                    code:           product.code,
                    description:    product.description,
                    price:          product.price,
                    uom:            product.uom,
                    count:          Math.ceil((customMeasure.window.height / 12) * customMeasure.window.quantity) || 0,
                    attributes:     {
                        category: 'Trim',
                        type: 'Flat Stock',
                        ...this.state.specialSpecs.trim.flat_stock
                    },
                    isSpecial: this.isSpecialSpecs('trim', 'flat_stock')
                };
                cap = update(cap, {
                    trim: {
                        "$merge": {flat_stock}
                    }
                });
                resolve(cap)
            } else if (
                /jamb/.test(cap.materialType) &&
                Lib.deepAttributeExists([cap.materialType, 'code'], this.state.specialSpecs.products)
            ) {
                const product = this.state.specialSpecs.products[cap.materialType];
                const jamb = {
                    code:           product.code,
                    description:    product.description,
                    price:          product.price,
                    uom:            product.uom,
                    count:          product.attributes.supplier === 'Commodity' ? 1 : customMeasure.window.height / 12,
                    attributes:     {
                        category: 'Trim',
                        type: 'Jamb',
                        ...this.state.specialSpecs.trim[cap.materialType]
                    },
                    isSpecial: this.isSpecialSpecs('trim', cap.materialType)
                };
                cap = update(cap, {
                    trim: {
                        "$merge": {jamb}
                    }
                });
                resolve(cap)
            }
            else { resolve(cap) }
        });
    };

    addArchWindow = async () => {
        const { apolloClient } = this.props;
        let custom = this.state.measures.custom;
        let archWindow = {
            id:         custom.window.id || null,
            itemIndex:  custom.window.itemIndex,
            unitNumber: custom.window.unitNumber,
            title:      custom.window.title,
            additionalItem:  custom.window.additionalItem,
            type:       custom.window.type,
            width:      Number(custom.window.width) || 0,
            height:     Number(custom.window.height) || 0,
            isHigh:     custom.window.isHigh,
            highOpeningLocation:     custom.window.highOpeningLocation,
            notes:      custom.window.notes,
            images:     custom.window.images,
            trim:       {},
            createdAt:  custom.window.createdAt,
            updatedAt:  custom.window.updatedAt,
        };
        let curveAttr = {
            category:   "Trim",
            type:       "Curve",
            species:    this.state.specialSpecs.trim.curve.species,
            style:      this.state.specialSpecs.trim.curve.style,
            finish:     this.state.specialSpecs.trim.curve.finish,
            width:      archWindow.width,
        };
        let curvePromise = Lib.getAnyProduct(curveAttr, apolloClient);
        return curvePromise.then(product => {
            if (product) {
                delete curveAttr.width; // we use it only in query
                const curve = {
                    code:           product.code,
                    description:    product.description,
                    price:          product.pricingTiers,
                    uom:            product.uom,
                    count:          1,
                    attributes:     curveAttr,
                    isSpecial:      this.isSpecialSpecs('trim', 'curve')
                };
                archWindow = update(archWindow, {
                    trim : {
                        $merge: {curve}
                    }
                });
                return archWindow
            }
        });
    };

    addArchway = async () => {
        const { apolloClient } = this.props;
        return new Promise( (resolve) => {
            let custom = this.state.measures.custom.window;
            let archway = {
                id:             custom.id || null,
                itemIndex:      custom.itemIndex,
                unitNumber:     custom.unitNumber,
                title:          custom.title,
                additionalItem: custom.additionalItem,
                type:           custom.type,
                width:          Number(custom.width) || 0,
                height:         Number(custom.height) || 0,
                openingDepth:   Number(custom.openingDepth) || 0,
                quantity:       custom.quantity,
                addBackband:    custom.addBackband,
                backbandSides:  custom.backbandSides,
                addArchitrave:  custom.addArchitrave,
                architraveSides:custom.architraveSides,
                materialType:   custom.materialType,
                customHeader:   custom.customHeader,
                hasExtraService:custom.hasExtraService,
                extraService:   custom.extraService,
                headerQuantity: custom.headerQuantity,
                headerMaterial: custom.headerMaterial,
                notes:          custom.notes,
                images:         custom.images,
                sheet:          {},
                trim:           {},
                hardware:       {},
                createdAt    :          custom.createdAt,
                updatedAt    :          custom.updatedAt,
            };
            let casing = this.state.specialSpecs.products.casing;
            if ("casing" in this.state.specialSpecs.products) {
                casing = update(casing, {
                    $merge: {
                        count : Math.ceil((2*archway.width/12) + (4*archway.height/12)),
                        isSpecial: this.isSpecialSpecs('trim', 'casing')
                    }
                })
            }
            archway = update (archway, {
                trim: {
                    "$merge": {casing: casing}
                }
            });
            // architrave
            if (
                archway.addArchitrave &&
                archway.architraveSides &&
                Lib.deepAttributeExists(['architrave', 'code'], this.state.specialSpecs.products)
            ) {
                const casingCount = Lib.deepAttributeExists(['casing', 'count'], archway.trim);
                const casingWidth = Lib.deepAttributeExists(['casing', 'attributes', 'width'], this.state.specialSpecs.products) || 0;
                const architraveWidth = (Number(archway.width) + (Lib.parseStringNumber(casingWidth) * 2) + 6) / 12;
                const architrave = this.state.specialSpecs.products.architrave;
                architrave.count = Math.ceil(architraveWidth) * (archway.architraveSides === 'two_sides' ? 2 : 1)
                architrave.isSpecial = this.isSpecialSpecs('trim', 'architrave');
                const newCasingCount = casingCount !== 0 ? archway.architraveSides === 'two_sides' ? casingCount - 7 : casingCount - 4 : 0;
                archway = {
                    ...archway,
                    trim:{
                        ...archway.trim,
                        architrave,
                        ...(
                            'casing' in archway.trim &&
                            archway.trim.casing ?
                                {
                                    casing: {
                                        ...archway.trim.casing,
                                        count: newCasingCount,
                                        isSpecial: this.isSpecialSpecs('trim', 'casing')
                                    }
                                } : {}
                        )
                    },
                }
            }
            // backband
            if (
                archway.addBackband &&
                archway.backbandSides &&
                Lib.deepAttributeExists(['backband', 'code'], this.state.specialSpecs.products)
            ) {
                const backband = this.state.specialSpecs.products.backband;
                const casingCount = Number(Lib.deepAttributeExists(['casing', 'count'], archway.trim) || 0);
                const backbandCount = archway.backbandSides === 'two_sides' ? casingCount : casingCount/2;
                backband.count = Math.ceil(backbandCount);
                backband.isSpecial = this.isSpecialSpecs('trim', 'backband');
                archway = {
                    ...archway,
                    trim:{
                        ...archway.trim,
                        backband,
                    },
                }
            }
            // Material
            if (
                archway.materialType &&
                Lib.deepAttributeExists([archway.materialType, 'code'], this.state.specialSpecs.products)
            ) {
                const realMaterial = /jamb/.test(archway.materialType) ? 'jamb' : archway.materialType;
                const product = this.state.specialSpecs.products[archway.materialType];
                let count;
                const hasCustomHeader = archway.customHeader && !Lib.isEmptyOrNullObject(this.state.specialSpecs.trim.archway_header || {});
                if (archway.materialType === 'sheet') {
                    count = archway.quantity;
                } else if (archway.materialType === 'flat_stock') {
                    count = hasCustomHeader ?
                        Math.ceil((archway.height * 2) / 12) :
                        Math.ceil((archway.height * 2 + Number(archway.width)) / 12);
                } else if (/jamb/.test(archway.materialType)) {
                    if (hasCustomHeader) {
                        count = product.attributes.supplier === 'Commodity' ?
                            2 :
                            Math.ceil((archway.height * 2 ) / 12);
                    } else {
                        count = product.attributes.supplier === 'Commodity' ?
                            archway.width > 36 ? 3 : 2.5 :
                            Math.ceil((archway.height * 2 + Number(archway.width)) / 12);
                    }
                }
                const material = {
                    code:           product.code,
                    description:    product.description,
                    price:          product.price,
                    uom:            product.uom,
                    count:          count,
                    attributes:     {
                        category: 'Trim',
                        type: Lib.humanize(archway.materialType),
                        ...this.state.specialSpecs.trim[archway.materialType]
                    },
                    isSpecial: this.isSpecialSpecs('trim', archway.materialType)
                };
                archway = update(archway, {
                    trim: {
                        "$merge": {
                            [realMaterial]: material
                        }
                    }
                });
                if ( hasCustomHeader ) {
                    const attributes = {
                        ...this.state.specialSpecs.trim.archway_header,
                        category: 'Trim',
                        type: /jamb/.test(archway.headerMaterial) ? 'Jamb' : archway.headerMaterial
                    };
                    Lib.getAnyProduct(attributes, apolloClient)
                    .then(product => {
                        if (product) {
                            let count;
                            if (archway.headerMaterial === 'sheet') {
                                count = archway.headerQuantity;
                            } else if (archway.headerMaterial === 'flat_stock') {
                                count = Math.ceil( Number(archway.width) / 12);
                            } else if (/jamb/.test(archway.headerMaterial)) {
                                count = attributes.supplier === 'Commodity' ?
                                        archway.width > 36 ? 1 : 0.5 :
                                        Math.ceil(Number(archway.width) / 12);
                            }
                            const material = {
                                code:           product.code,
                                description:    product.description,
                                price:          product.pricingTiers,
                                uom:            product.uom,
                                count:          count,
                                isSpecial: true,
                                attributes,
                            };
                            archway = update(archway, {
                                trim: {
                                    "$merge": {
                                        "archway_header": material
                                    }
                                }
                            });
                        }
                        resolve(archway)
                    })
                } else {
                    resolve(archway)
                }
            } else {
                resolve(archway)
            }

        })
    };

    addAEDoor = async () => {
        const { apolloClient } = this.props;
        let custom = this.state.measures.custom.window;
        let extDoor = {
            id:                 custom.id || null,
            itemIndex:          custom.itemIndex,
            unitNumber:         custom.unitNumber,
            title:              custom.title,
            additionalItem:     custom.additionalItem,
            type:               custom.type,
            size:               custom.size,
            notes:              custom.notes,
            images:             custom.images,
            operating:          custom.operating,
            handleType:         custom.handleType,
            extHardwareMode:    custom.extHardwareMode,
            extHardwareMode2:   custom.extHardwareMode2,
            extHandleMode:      custom.extHandleMode,
            extHandleMode2:     custom.extHandleMode2,
            hardwareOptions:    custom.hardwareOptions,
            doorstopper_mode:    custom.doorstopper_mode,
            doorstopper_mode_left:    custom.doorstopper_mode_left,
            doorstopper_mode_right:    custom.doorstopper_mode_right,
            hasBurlap:          custom.hasBurlap,
            closerCount:        custom.closerCount,
            hasCloser:          custom.hasCloser,
            addArchitrave:      custom.addArchitrave,
            architraveWidth:    Number(custom.architraveWidth) || 0,
            addBackband:        custom.addBackband,
            addBuildout:        custom.addBuildout,
            buildoutDepth  :    Number(custom.buildoutDepth) || 0,
            quantity  :         custom.quantity,
            materialType  :     custom.materialType,
            openingDepth  :     Number(custom.openingDepth) || 0,
            trim: {},
            hardware: {},
            createdAt    :          custom.createdAt,
            updatedAt    :          custom.updatedAt,
        };
        let casingCount = Lib.getExtDoorCasingBurlap(extDoor.size, "casing");
        let burlapCount = custom.burlapCount || Lib.getExtDoorCasingBurlap(extDoor.size, "burlap");

        let buildout = {};
        if (
            extDoor.addBuildout &&
            extDoor.materialType &&
            !Lib.isEmptyOrNullObject(this.state.specialSpecs.products[extDoor.materialType])
        ) {
            buildout = this.state.specialSpecs.products[extDoor.materialType];
            let totalBuildout = 0;
            if (extDoor.materialType=== 'flat_stock') {
                totalBuildout = Math.ceil(extDoor.quantity * casingCount);
            } else if (extDoor.materialType=== 'sheet') {
                totalBuildout = extDoor.quantity || 0;
            }
            buildout.count = totalBuildout;
            buildout.isSpecial = this.isSpecialSpecs('trim', extDoor.materialType);
        }

        const architraveWidth = extDoor.addArchitrave ? Math.ceil(Number(extDoor.architraveWidth) / 12) : 0;
        const newPerimeter = casingCount - architraveWidth;

        extDoor = {
            ...extDoor,
            trim: {
                ...extDoor.trim,
                ...(
                    extDoor.addArchitrave &&
                    {
                        architrave : {
                            ...this.state.specialSpecs.products.architrave,
                            count: architraveWidth,
                            isSpecial: this.isSpecialSpecs('trim', 'architrave')
                        },
                    }
                ),
                ...(
                    Lib.deepAttributeExists(['casing', 'code'], this.state.specialSpecs.products) &&
                    {
                        casing: {
                            ...this.state.specialSpecs.products.casing,
                            count: casingCount > 0 ? newPerimeter : 0,
                            isSpecial: this.isSpecialSpecs('trim', 'casing')
                        },
                    }
                ),
                ...(
                    extDoor.addBuildout &&
                    extDoor.materialType &&
                    !Lib.isEmptyOrNullObject(buildout) ? {[extDoor.materialType] : buildout} : {}
                ),
                ...(
                    extDoor.addBackband &&
                    this.state.specialSpecs.products.backband ?
                        {
                            backband : {
                                ...this.state.specialSpecs.products.backband,
                                count: casingCount > 0 ? newPerimeter : 0,
                                isSpecial: this.isSpecialSpecs('trim', 'backband')
                            }
                        } : {}
                ),
                ...(
                    extDoor.size.indexOf("side") > -1 &&
                    custom.hasBurlap &&
                    "burlap" in this.state.specialSpecs.products ?
                        {
                            burlap: {
                                ...this.state.specialSpecs.products.burlap,
                                count: burlapCount,
                                isSpecial: this.isSpecialSpecs('trim', 'burlap')
                            }
                        }
                        : {}
                )
            }
        }
        // Hardware option
        let hardwareTypes = custom.hardwareOptions.split("_");
        if (extDoor.hasCloser) hardwareTypes.push("closer");
        const hardwarePromises = hardwareTypes.map( type => new Promise( (resolve) => {
            if (['deadbolt', 'closer'].includes(type)) {
                if (Lib.deepAttributeExists([type, 'code'], this.state.specialSpecs.products)) {
                    let obj = {
                        ...this.state.specialSpecs.products[type],
                        count: extDoor.size === 'double' &&  ['gripset', 'passage', 'interior'].includes(type) ?
                            1 : type === 'closer' ? Number(extDoor.closerCount) : 1,
                        isSpecial: this.isSpecialSpecs('hardware', type)
                    };
                    extDoor = update(extDoor,{
                        hardware: {"$merge": {[type]: obj}}
                    })
                    resolve(1)
                } else {
                    resolve(2);
                }
            } else if (
                (
                    type === 'gripset' && 
                    !Lib.isEmptyOrNullObject(this.state.specialSpecs.hardware.gripset)
                ) || 
                extDoor.handleType
            ) {
                const mode = type === 'gripset' ? extDoor.extHardwareMode : extDoor.extHandleMode;
                if (
                    mode &&
                    !Lib.isEmptyOrNullObject(this.state.specialSpecs.hardware[type === 'gripset' ? 'gripset' : extDoor.handleType])
                ) {
                    const attributes = {
                        ...this.state.specialSpecs.hardware[type === 'gripset' ? 'gripset' : extDoor.handleType],
                        category: 'Hardware',
                        type: type === 'gripset' ? 'Gripset' : extDoor.handleType,
                        mode,
                        ...(extDoor.handleType === 'lever' && type !== 'gripset' && {
                            direction: extDoor.operating === 'left' ? 'LH' : 'RH'
                        })
                    };
                    Lib.getAnyProduct(attributes, apolloClient)
                    .then(product => {
                        if (product) {
                            delete attributes.mode;
                            delete attributes.direction;
                            let obj = {
                                code:           product.code,
                                description:    product.description,
                                price:          product.pricingTiers,
                                uom:            product.uom,
                                attributes,
                                count: 1,
                                isSpecial: this.isSpecialSpecs('hardware', type === 'gripset' ? 'gripset' : extDoor.handleType)
                            };
                            extDoor = {
                                ...extDoor,
                                hardware: {
                                    ...extDoor.hardware,
                                    ...(
                                        extDoor.size === 'double' ? {
                                            double: {
                                                ...((extDoor.hardware && extDoor.hardware.double) || {}),
                                                [extDoor.operating] : {
                                                    ...(((extDoor.hardware && extDoor.hardware.double) && extDoor.hardware.double[extDoor.operating]) || {}),
                                                    [type === 'gripset' ? 'gripset' : extDoor.handleType]: obj
                                                }
                                            }
                                        } : {
                                            [type === 'gripset' ? 'gripset' : extDoor.handleType]: obj
                                        }
                                    )
                                }
                            };
                            if (
                                extDoor.size === 'double' &&
                                (
                                    (type === 'gripset' && extDoor.extHardwareMode2) ||
                                    (type !== 'gripset' && extDoor.extHandleMode2)
                                )
                            ) {
                                const mode = type === 'gripset' ? extDoor.extHardwareMode2 : extDoor.extHandleMode2
                                const attributes = {
                                    ...this.state.specialSpecs.hardware[type === 'gripset' ? 'gripset' : extDoor.handleType],
                                    category: 'Hardware',
                                    type: type === 'gripset' ? 'gripset' : extDoor.handleType,
                                    mode,
                                    ...(extDoor.handleType === 'lever' && type !== 'gripset' && {
                                        direction: extDoor.operating === 'left' ? 'RH' : 'LH'
                                    })
                                };
                                Lib.getAnyProduct(attributes, apolloClient)
                                .then(product2 => {
                                    if (product2) {
                                        delete attributes.mode;
                                        delete attributes.direction;
                                        let obj2 = {
                                            code:           product2.code,
                                            description:    product2.description,
                                            price:          product2.pricingTiers,
                                            uom:            product2.uom,
                                            attributes,
                                            count: 1,
                                            isSpecial: this.isSpecialSpecs('hardware', type === 'gripset' ? 'gripset' : extDoor.handleType)
                                        };
                                        extDoor = {
                                            ...extDoor,
                                            hardware: {
                                                ...extDoor.hardware,
                                                double: {
                                                    ...((extDoor.hardware && extDoor.hardware.double) || {}),
                                                    [extDoor.operating === 'left' ? 'right' : 'left'] : {
                                                        ...(((extDoor.hardware && extDoor.hardware.double) && extDoor.hardware.double[extDoor.operating === 'left' ? 'right' : 'left']) || {}),
                                                        [type === 'gripset' ? 'gripset' : extDoor.handleType]: obj2
                                                    }
                                                }
                                            }
                                        }
                                        resolve(6)
                                    }
                                })
                            } else {
                                resolve(7);
                            }
                            // resolve(3)
                        } else {
                            resolve(4);
                        }
                    })
                } else if (
                    extDoor.size !== 'double' ||
                    !(
                        (type === 'gripset' && extDoor.extHardwareMode2) ||
                        (type !== 'gripset' && extDoor.extHandleMode2)
                    )
                ) {
                    resolve(5);
                }
                // Double Ext Door Fixed Handles
                
            }
            else resolve(8)
        }));
        return Promise.all(hardwarePromises).then( async () => {
            const extDoorWDoorstopper = await this.addAEDoorstopper(extDoor);
            return extDoorWDoorstopper;
        })
    };

    addAEDoorstopper = async (extDoor) => {
        const { apolloClient } = this.props;
        if (
            extDoor.size === 'double' &&
            Lib.deepAttributeExists(['doorstopper', 'finish'], this.state.specialSpecs.hardware)
        ) {
            for (const side of ['left', 'right']) {
                if (extDoor[`doorstopper_mode_${side}`]) {
                    let attributes = {
                        category: "Hardware",
                        type: "Doorstopper",
                        finish: this.state.specialSpecs.hardware.doorstopper.finish,
                        mode: extDoor[`doorstopper_mode_${side}`]
                    };
                    const product = await Lib.getAnyProduct(attributes, apolloClient);
                    if (product) {
                        delete attributes.mode;
                        let doorstopperProduct = {
                            attributes: attributes,
                            code: product.code,
                            uom: product.uom,
                            count: 1,
                            description: product.description,
                            price: product.pricingTiers,
                            isSpecial: this.isSpecialSpecs('hardware', 'doorstopper')
                        };
                        extDoor = {
                            ...extDoor,
                            hardware: {
                                ...extDoor.hardware,
                                ...(
                                    extDoor.size === 'double' ? {
                                        double: {
                                            ...((extDoor.hardware && extDoor.hardware.double) || {}),
                                            [side] : {
                                                ...(((extDoor.hardware && extDoor.hardware.double) && extDoor.hardware.double[side]) || {}),
                                                doorstopper: doorstopperProduct
                                            }
                                        }
                                    } : {
                                        doorstopper: doorstopperProduct
                                    }
                                )
                            }
                        }
                    }
                }
            }
            return extDoor;
        } else if (
            extDoor.size &&
            extDoor.doorstopper_mode &&
            Lib.deepAttributeExists(['doorstopper', 'finish'], this.state.specialSpecs.hardware)
        ) {
            let attributes = {
                category: "Hardware",
                type: "Doorstopper",
                finish: this.state.specialSpecs.hardware.doorstopper.finish,
                mode: extDoor[`doorstopper_mode`]
            };
            const product = await Lib.getAnyProduct(attributes, apolloClient);
            if (product) {
                delete attributes.mode;
                let doorstopperProduct = {
                    attributes: attributes,
                    code: product.code,
                    uom: product.uom,
                    count: 1,
                    description: product.description,
                    price: product.pricingTiers,
                    isSpecial: this.isSpecialSpecs('hardware', 'doorstopper')
                };
                extDoor = update (extDoor, {
                    hardware: {
                        $merge: {doorstopper: doorstopperProduct}
                    }
                })
                return extDoor;
            }
        } else {
            return extDoor
        }
    }

    addAnOpening = async (type) => {
        switch (type) {
            case 'window':
                return this.addAWindow();
            case 'exterior_door':
                return this.addAEDoor();
            case 'archway':
                return this.addArchway();
            case 'caps':
                return this.addCaps();
            case 'arch_window':
                return this.addArchWindow();
            case 'shower_stall':
                return this.addShowerStall();
            case 'stairs':
                return this.addStairs();
            case 'attic':
                return this.addAttic();
            case 'column':
                return this.addColumn();
            default:
        }
    };

    addShowerStall = () => {
        const opening = this.state.measures.custom.window;
        let newStall = {
            type:                  "shower_stall",
            id:                     opening.id || null,
            itemIndex:              opening.itemIndex,
            unitNumber:             opening.unitNumber,
            title:                  opening.title,
            additionalItem:         opening.additionalItem,
            notes:                  opening.notes,
            images:                 opening.images,
            createdAt    :          opening.createdAt,
            updatedAt    :          opening.updatedAt,
            materialType    :       opening.materialType,
            trim: {
                ...(
                    opening.materialType in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products[opening.materialType] ?
                        {
                            [opening.materialType] : {
                                ...this.state.specialSpecs.products[opening.materialType],
                                count: 21,
                                isSpecial: this.isSpecialSpecs('trim', 'casing')
                            }
                        } : {}
                ),
            }
        };
        return newStall;
    };

    addStairs = () => {
        const opening = this.state.measures.custom.window;
        let newStairs = {
            type       :            "stairs",
            id:                     opening.id || null,
            itemIndex:              opening.itemIndex,
            unitNumber:             opening.unitNumber,
            title:                  opening.title,
            attributes:             opening.attributes,
            layout:                 opening.layout,
            quantity:               opening.quantity,
            additionalItem:         opening.additionalItem,
            materialType:           opening.materialType,
            continuousHandrail:     opening.addHandrail && opening.continuousHandrail,
            addHandrail:            opening.addHandrail,
            addCasing:              opening.addCasing,
            addD4s:                 opening.addD4s,
            materialLength:         opening.materialLength,
            createdAt    :          opening.createdAt,
            updatedAt    :          opening.updatedAt,
            coveLength:             Number(opening.coveLength) || 0,
            d4sLength:              Number(opening.d4sLength) || 0,
            casingLength:           Number(opening.casingLength) || 0,
            handrailLength:         Number(opening.handrailLength) || 0,
            trim: {
                ...(
                    Lib.deepAttributeExists(['cove', 'code'], this.state.specialSpecs.products) &&
                    {
                        cove: {
                            ...this.state.specialSpecs.products.cove,
                            count: opening.coveLength || 0,
                            isSpecial: this.isSpecialSpecs('trim', 'cove')
                        }
                    }
                ),
                ...(
                    opening.materialType &&
                    opening.materialType in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products[[opening.materialType]] &&
                    {
                        [opening.materialType]: {
                            ...this.state.specialSpecs.products[opening.materialType],
                            count: opening.materialLength,
                            isSpecial: this.isSpecialSpecs('trim', opening.materialType)
                        }
                    }
                ),
                ...(
                    opening.addD4s &&
                    "flat_stock" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.flat_stock &&
                    {
                        flat_stock: {
                            ...this.state.specialSpecs.products.flat_stock,
                            count: opening.d4sLength,
                            isSpecial: this.isSpecialSpecs('trim', 'flat_stock')
                        }
                    }
                ),
                ...(
                    opening.addCasing &&
                    "casing" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.casing &&
                    {
                        casing: {
                            ...this.state.specialSpecs.products.casing,
                            count: opening.casingLength,
                            isSpecial: this.isSpecialSpecs('trim', 'casing')
                        }
                    }
                ),
                ...(
                    opening.addHandrail &&
                    "handrail" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.handrail &&
                    {
                        handrail: {
                            ...this.state.specialSpecs.products.handrail,
                            count: opening.handrailLength,
                            isSpecial: this.isSpecialSpecs('trim', 'handrail')
                        }
                    }
                )
            },
            hardware: {
                ...(
                    opening.addHandrail &&
                    "handrail_bracket" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.handrail_bracket &&
                    {
                        handrail_bracket: {
                            ...this.state.specialSpecs.products.handrail_bracket,
                            count: Number(opening.handrailLength) < 8 ? 2 : Math.floor(opening.handrailLength / 4) + 1,
                            isSpecial: this.isSpecialSpecs('hardware', 'handrail_bracket')
                        }
                    }
                )
            },
            notes:                  opening.notes,
            images:                 opening.images,
        };
        return newStairs;
    };

    addAttic = () => {
        const opening = this.state.measures.custom.window;
        let newAttic = {
            type       :            "attic",
            id:                     opening.id || null,
            itemIndex:              opening.itemIndex,
            unitNumber:             opening.unitNumber,
            title:                  opening.title,
            additionalItem:         opening.additionalItem,
            notes:                  opening.notes,
            images:                 opening.images,
            createdAt    :          opening.createdAt,
            updatedAt    :          opening.updatedAt,
            trim: {
                ...(
                    "casing" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.casing ?
                        {
                            casing : {
                                ...this.state.specialSpecs.products.casing,
                                count: 10,
                                isSpecial: this.isSpecialSpecs('trim', 'casing')
                            }
                        } : {}
                ),
                ...(
                    "flat_stock" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.flat_stock ?
                        {
                            flat_stock : {
                                ...this.state.specialSpecs.products.flat_stock,
                                count: 10,
                                isSpecial: this.isSpecialSpecs('trim', 'flat_stock')
                            }
                        } : {}
                ),
                ...(
                    "attic_lid" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.attic_lid ?
                        {
                            attic_lid : {
                                ...this.state.specialSpecs.products.attic_lid,
                                count: 1,
                                isSpecial: this.isSpecialSpecs('trim', 'attic_lid')
                            }
                        } : {}
                ),
                ...(
                    "attic_hatch" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.attic_hatch ?
                        {
                            attic_hatch : {
                                ...this.state.specialSpecs.products.attic_hatch,
                                count: 1,
                                isSpecial: this.isSpecialSpecs('trim', 'attic_hatch')
                            }
                        } : {}
                ),
                ...(
                    "attic_foam_tape" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.attic_foam_tape ?
                        {
                            attic_foam_tape : {
                                ...this.state.specialSpecs.products.attic_foam_tape,
                                count: 1,
                                isSpecial: this.isSpecialSpecs('trim', 'attic_foam_tape')
                            }
                        } : {}
                ),
            }
        };
        return newAttic;
    };

    addColumn = () => {
        const opening = this.state.measures.custom.window;
        let newAttic = {
            type       :            "column",
            id:                     opening.id || null,
            itemIndex:              opening.itemIndex,
            unitNumber:             opening.unitNumber,
            title:                  opening.title,
            additionalItem:         opening.additionalItem,
            notes:                  opening.notes,
            images:                 opening.images,
            addSplit:               opening.addSplit,
            createdAt    :          opening.createdAt,
            updatedAt    :          opening.updatedAt,
            trim: {
                ...(
                    "column" in this.state.specialSpecs.products &&
                    this.state.specialSpecs.products.column ?
                        {
                            column : {
                                ...this.state.specialSpecs.products.column,
                                count: 1,
                                isSpecial: this.isSpecialSpecs('trim', 'column')
                            }
                        } : {}
                ),
            }
        };
        return newAttic;
    };

    addAWindow = () => {
        // const openings = this.state.windows;
        const opening = this.state.measures.custom.window;
        const sizes  = {"small" : ["3","4"],"medium" : ["4","6"],"large" : ["6","8"]};
        const size   = opening.size;
        const width  = size === "custom" ? opening.width  : sizes[size][0]
        const height = size === "custom" ? opening.height : sizes[size][1]
        const fullPerimeter = Lib.calculateWindowDimensions(2*width, 2*height, size === "custom"? true : false );
        const architraveWidth = opening.addArchitrave ? Math.ceil(Number(opening.architraveWidth || 0) / 12) : 0;
        const partialPerimeter = Number(fullPerimeter) - Number( width / (size === "custom" ? 12 : 1) );
        let windowTrim = {
            ...(
                opening.addArchitrave &&
                "architrave" in this.state.specialSpecs.products &&
                this.state.specialSpecs.products.architrave ?
                    {
                        architrave : {
                            ...this.state.specialSpecs.products.architrave,
                            count: architraveWidth,
                            isSpecial: this.isSpecialSpecs('trim', 'architrave')
                        },
                    } : {}
            ),
            ...(
                "casing" in this.state.specialSpecs.products &&
                "casing" in this.state.specialSpecs.products &&
                this.state.specialSpecs.products.casing ?
                    {
                        casing : {
                            ...this.state.specialSpecs.products.casing,
                            count: opening.addArchitrave ? partialPerimeter : fullPerimeter,
                            isSpecial: this.isSpecialSpecs('trim', 'casing')
                        }
                    } : {}
            ),
            ...(
                opening.addBuildout &&
                opening.materialType &&
                !Lib.isEmptyOrNullObject(this.state.specialSpecs.products[opening.materialType]) &&
                {
                    [opening.materialType] : {
                        ...this.state.specialSpecs.products[opening.materialType],
                        isSpecial: this.isSpecialSpecs('trim', opening.materialType),
                        count: opening.materialType === 'flat_stock' ? (opening.quantity * fullPerimeter) : opening.quantity || 0
                    }
                }
            ),
            ...(
                opening.hasBurlap &&
                "burlap" in this.state.specialSpecs.products &&
                this.state.specialSpecs.products.burlap ?
                    {
                        burlap : {
                            ...this.state.specialSpecs.products.burlap,
                            count:  opening.burlapCount || fullPerimeter,
                            isSpecial: this.isSpecialSpecs('trim', 'burlap')
                        }
                    } : {}
            ),
            ...(
                opening.addBackband &&
                "backband" in this.state.specialSpecs.products &&
                this.state.specialSpecs.products.backband ?
                    {
                        backband : {
                            ...this.state.specialSpecs.products.backband,
                            count: (opening.addArchitrave ? partialPerimeter : fullPerimeter) || 0,
                            isSpecial: this.isSpecialSpecs('trim', 'backband')
                        }
                    } : {}
            ),
            ...(
                opening.addSill &&
                "sill" in this.state.specialSpecs.products &&
                this.state.specialSpecs.products.sill
                ? {
                    sill : {
                        ...this.state.specialSpecs.products.sill,
                        count: Math.ceil(((opening.sillLength || 0) / 12)),
                        isSpecial: this.isSpecialSpecs('trim', 'sill')
                    }
                } : {}
            )
        };
        let newWindow = {
            type       :            "window",
            size       :            size,
            width      :            Number(width) || 0,
            height     :            Number(height) || 0,
            perimeter  :            fullPerimeter || 0,
            id:                     opening.id || null,
            itemIndex:              opening.itemIndex,
            unitNumber:             opening.unitNumber,
            title:                  opening.title,
            additionalItem:         opening.additionalItem,
            addArchitrave:          opening.addArchitrave,
            hasExtraService:        opening.hasExtraService,
            extraService:           opening.extraService,
            architraveWidth:        Number(opening.architraveWidth) || 0,
            hasBurlap:              opening.hasBurlap,
            addBackband:            opening.addBackband,
            addBuildout:            opening.addBuildout,
            buildoutDepth  :        Number(opening.buildoutDepth) || 0,
            sillDepth  :            Number(opening.sillDepth) || 0,
            sillLength  :           Number(opening.sillLength) || 0,
            notes:                  opening.notes,
            images:                 opening.images,
            addSill    :            opening.addSill,
            isHigh    :             opening.isHigh,
            highOpeningLocation:    opening.highOpeningLocation,
            materialType    :       opening.materialType,
            openingDepth    :       Number(opening.openingDepth) || 0,
            quantity    :           opening.quantity,
            createdAt    :          opening.createdAt,
            updatedAt    :          opening.updatedAt,
            trim:                   windowTrim,
            isSpecial:              false,
        };
        return newWindow;
    };

    populateSpecProduct = (category, type) => {
        const { apolloClient } = this.props;
        let attributes = this.state.specs[category][type];
        attributes.category = category;
        attributes.type = type;
        if ("trimlength" in attributes)
            delete attributes.trimlength;
        if (
            (type === "catches"   && this.isSpecSet("style",category, type) && this.isSpecSet("finish",category, type)) ||
            (type === "flushbolt" && this.isSpecSet("style",category, type) && this.isSpecSet("length",category, type) && this.isSpecSet("width",category, type)  && this.isSpecSet("finish",category, type)) ||
            (type === "hinge"     && this.isSpecSet("style",category, type) && this.isSpecSet("width",category, type)  && this.isSpecSet("height",category, type) && this.isSpecSet("finish",category, type)) ||
            ([  "doorstop",
                "shoe_moulding",
                "casing"].includes(type) && this.isSpecSet("supplier",category, type) && this.isSpecSet("style",category, type) && this.isSpecSet("species",category, type) && this.isSpecSet("width",category, type) && this.isSpecSet("thickness",category, type)) ||
            ([  "gripset",
                "deadbolt",
                "closer"
            ].includes(type) && this.isSpecSet("brand",category, type) && this.isSpecSet("style",category, type) && this.isSpecSet("finish",category, type))
        ) {
            let productPromise = Lib.getAnyProduct(attributes, apolloClient)
            productPromise.then( product => {
                if (product) {
                    let newState = update(this.state, {
                        specs: {
                            products: {
                                [type]: {
                                    $set : {
                                        code:           product.code,
                                        uom:           product.uom,
                                        description:    product.description,
                                        price:          product.pricingTiers
                                    }}
                            }
                        }
                    });
                    this.setState(newState, () => {
                        this.updateStandardProducts(category, type)
                    })
                }
            })
        }
    };

    populateTakeoff = (takeoff) => {
        this.setState({
            ...this.state,
            loadingModal: false,
            isLocked: takeoff.isLocked,
            publicCode: takeoff.publicCode,
            linkExpiresAt: takeoff.linkExpiresAt,
            location: (takeoff.location && takeoff.location.value) || '',
            lockedAt: takeoff.lockedAt,
            creator: takeoff.createdBy.username,
            creator_name: takeoff.createdBy.name,
            createdAt: takeoff.createdAt,
            isAdditionalOrder: takeoff.isAdditionalOrder,
            additionalOrder: takeoff.additionalOrder,
            status: takeoff.status ? takeoff.status.toLowerCase() : "" ,
            productUpdated: this.state.productUpdated+1,
            loading: false,
            uploader : null,
            takeoffid: "",
            code: takeoff.code,
            notes: takeoff.notes || '',
            installPricingMode: takeoff.installPricingMode,
            installAt: takeoff.installAt,
            takeoffEditId: takeoff.id,
            customerType: takeoff.customerType,
            customer: takeoff.customer || {},
            prospect: takeoff.prospect || {},
            specs: takeoff.specs,
            site: takeoff.site,
            doors:      takeoff.measure.doors && Array.isArray(takeoff.measure.doors)        ? [...takeoff.measure.doors]    : [],
            shelving:   takeoff.measure.shelving && Array.isArray(takeoff.measure.shelving)  ? [...takeoff.measure.shelving] : [],
            windows:    takeoff.measure.windows && Array.isArray(takeoff.measure.windows)    ? [...takeoff.measure.windows]  : [],
            hardware:   takeoff.measure.hardware && Array.isArray(takeoff.measure.hardware)  ? [...takeoff.measure.hardware] : [],
            trim:       takeoff.measure.trim && Array.isArray(takeoff.measure.trim)          ? [...takeoff.measure.trim]     : [],
            custom_products:   [...takeoff.measure.custom_products] || [],
            custom_install_services:   [...takeoff.measure.custom_install_services] || [],
            measures: {
                ...this.state.measures,
            }
        })
    };

    saveRemoteSpecs = async ({category, type, specs, product, notes, propagate}) => {
        const { apolloClient } = this.props;
        if (propagate) this.setState({loadingModal: true});
        const attributes = {
            id: this.state.takeoffEditId || null,
            category,
            type,
            specs,
            product,
            propagate,
            notes
        };
        const takeoffPromise = Lib.saveSpecsType(attributes, apolloClient);
        return takeoffPromise.then(takeoff => {
            if (takeoff) {
                if (propagate) {
                    this.populateTakeoff(takeoff);
                } else {
                    this.setState({
                        takeoffEditId: takeoff.id,
                        code: takeoff.code,
                        productUpdated: this.state.productUpdated + 1,
                        ...(!attributes.id &&{
                            creator: takeoff.createdBy.username || "",
                            creator_name: takeoff.createdBy.name || "",
                        })
                    }, () => {
                        if (!attributes.id) {
                            this.setPrimaryTab('specs',true, takeoff.id);
                        }
                    });
                }
            }
        })
    };

    changeRemoteCustomer = async ({customer, updatePricing, type}) => {
        const { apolloClient } = this.props;
        if (updatePricing) {
            this.setState({loading: true});
        }
        const attributes = {
            id: this.state.takeoffEditId || null,
            customer,
            updatePricing,
            customerType: type,
        };
        const takeoffPromise = Lib.changeCustomer(attributes, apolloClient);
        takeoffPromise.then(takeoff => {
            if (takeoff) {
                if (updatePricing) {
                    this.populateTakeoff(takeoff);
                } else {
                    this.setState({
                        takeoffEditId: takeoff.id,
                        code: takeoff.code,
                        ...(!attributes.id &&{
                            primaryTab: 'customer',
                            creator: takeoff.createdBy.username || "",
                            creator_name: takeoff.createdBy.name || "",
                        })
                    }, () => {
                        if (!attributes.id) {
                            this.setPrimaryTab('customer',true, takeoff.id);
                        }
                    });
                }
            }
        })
    };

    remoteDuplicateMeasureItem = ({category, index, maxIndex, count}) => {
        const { apolloClient } = this.props;
        const item_id = this.state[category][index]['id'];
        if (item_id) {
            const duplicatePromise = Lib.duplicateMeasureItem({
                id: this.state.takeoffEditId,
                category,
                item_id: this.state[category][index]['id'],
                index: maxIndex,
                count
            }, apolloClient);
            duplicatePromise.then(res => {
                if (res) {
                    let counter   =  0;
                    let pointer = this.state[category].length - count;
                    const newCategory = this.state[category].map((item, i) => {
                        if (i === pointer) {
                            const newItem = {
                                ...item,
                                id:         res.items[counter].id,
                                createdAt:  res.items[counter].createdAt,
                                updatedAt:  res.items[counter].updatedAt,
                            };
                            pointer++;
                            counter++;
                            return newItem;
                        } else return item;
                    });
                    this.setState({
                        [category]: newCategory
                    });
                }
            })
        } else {
            // TODO: Show notification of failure
        }
    };

    saveMeasureItem = async ({category, index = null, item = null}) => {
        const { apolloClient } = this.props;
        const id = (index !== null && this.state[category][index]) ? this.state[category][index]['id'] : null;
        const attributes = {
            id: this.state.takeoffEditId || null,
            category,
            index: id,
            item
        };
        const takeoffPromise = Lib.saveMeasureItem(attributes, apolloClient);
        takeoffPromise.then(takeoff => {
            if (takeoff) {
                const newItem = {
                    ...item,
                    id:        takeoff.item.id,
                    createdAt: takeoff.item.createdAt ? takeoff.item.createdAt : this.state[category][index]['createdAt'],
                    updatedAt: takeoff.item.updatedAt,
                }
                const newCategory =  ![null, -1, '-1'].includes(index) ?
                        this.state[category].map((item, i) => i === index ? newItem : item) :
                        this.state[category].map((item, i) => i === (this.state[category].length - 1) ? newItem : item);
                this.setState( {
                    takeoffEditId: takeoff.id,
                    code: takeoff.code,
                    ...(!attributes.id &&{
                        creator: takeoff.createdBy.username || "",
                        creator_name: takeoff.createdBy.name || "",
                    }),
                    [category]: newCategory
                }, () => {
                    if (!attributes.id) {
                        this.setPrimaryTab('measure',true, takeoff.id);
                    }
                });
            }
        })
    };

    saveRemoteAttribute = async ({category, specs = null, site= null, value = null}) => {
        const { apolloClient } = this.props;
        const attributes = {
            id: this.state.takeoffEditId || null,
            attribute: category,
            specs,
            site,
            value
        };
        const takeoffPromise = Lib.saveAttribute(attributes, apolloClient);
        return takeoffPromise.then(takeoff => {
            if (takeoff) {
                this.setState({
                    takeoffEditId: takeoff.id,
                    code: takeoff.code,
                    ...(!attributes.id &&{
                        creator: takeoff.createdBy.username || "",
                        creator_name: takeoff.createdBy.name || "",
                    })
                }, () => {
                    if (!attributes.id) {
                        this.setPrimaryTab('customer',true, takeoff.id);
                    }
                });
            }
        })
    };

    removeMeasureItem = async ({category, index, itemIndex }) => {
        const { apolloClient } = this.props;
        await Lib.removeMeasureItem({
            id: this.state.takeoffEditId || null,
            category,
            index,
            itemIndex
        }, apolloClient)
    };

    setPrimaryTab = (tab, withState = true, id = null) => {
        const base = id || this.props.takeoffid ? `/updateTakeoff/${id || this.props.takeoffid}` : '/newTakeoff';
        withState && this.setState({primaryTab: tab});
        this.props.history.push(`${base}/${tab}`);
    }

    isSpecialSpecs = (category, type) => type ? Lib.isSpecialSpecs(this.state.specialSpecs[category][type], this.state.specs[category][type]) : Lib.isSpecialSpecs(this.state.specialSpecs[category], this.state.specs[category], true);

    clearMeasureForm = async (obj) => {
        return this.setState({
            addDoorDialog: false,
            addDoorData: "",
            measures: measureState,
            specialSpecsExpanded:   false,
            specialSpecs: specs,
            editDoorIndex: -1,
            editTrimIndex: -1,
            editWindowIndex: -1,
            editHardwareIndex: -1,
            editShelvingIndex: -1,
            ...obj
        })
    };

    getDoorHardwareAttributes = async (door) => {
        const { apolloClient } = this.props;
        let promises = [];
        let hardware = {};
        if ("hardware" in door) {
            Object.keys(door.hardware)
            .filter(type => door.hardware[type])
            .forEach(type => {
                if (
                    ["pocket", "finger_pull", "barn_door_track", "bipass_door_track"].includes(type) &&
                    "code" in door.hardware[type] &&
                    Lib.hasValue(door.hardware[type].code)
                ) {
                    let productPromise = new Promise((resolve) => {
                        let productFetcher = Lib.getAnyProduct({
                                code: door.hardware[type].code
                            },
                            apolloClient,
                            ["brand", "style", "finish", "mode", "type", "mode", "tracklength", "size"]
                        );
                        productFetcher.then(product => {
                            if (product) {
                                resolve({[type]: product})
                            }
                            else {
                                resolve({[type]: {}})
                            }
                        })
                    });
                    promises.push(productPromise);
                }
            })
        }
        return Promise.all(promises).then(values => {
            values.forEach( array => {
                Object.keys(array).forEach( type => {
                    hardware[type] = {};
                    switch (type) {
                        case "pocket":
                            hardware[type]["brand"] = array[type].brand;
                            hardware[type]["mode"] = array[type].mode;
                            hardware[type]["style"] = array[type].style;
                            hardware[type]["finish"] = array[type].finish;
                            break;
                        case "finger_pull":
                            hardware[type]["finish"] = array[type].finish;
                            hardware[type]["size"] = array[type].size;
                            break;
                        case "barn_door_track":
                            hardware[type]["brand"] = array[type].brand;
                            hardware[type]["finish"] = array[type].finish;
                            hardware[type]["tracklength"] = array[type].tracklength;
                            break;
                        case "bipass_door_track":
                            hardware[type]["brand"] = array[type].brand;
                            hardware[type]["tracklength"] = array[type].tracklength;
                            break;
                        default:

                    }
                })
            });
            return hardware;
        })
    };

    getExtDoorHardwareAttributes = async (extDoor) => {
        const { apolloClient } = this.props;
        let promises = [];
        let hardware = this.state.measures.custom.window.hardware;
        if ("hardware" in extDoor) {
            Object.keys(extDoor.hardware)
            .forEach(type => extDoor.hardware[type])
            .forEach(type => {
                if ("code" in extDoor.hardware[type] && Lib.hasValue(extDoor.hardware[type].code)) {
                    let productPromise = new Promise((resolve) => {
                        let productFetcher = Lib.getAnyProduct(
                            {code: extDoor.hardware[type].code},
                            apolloClient,
                            ["brand", "style", "finish", "mode", "type", "direction"]
                        );
                        productFetcher.then(product => {
                            if (product) {
                                resolve({[type]: product})
                            }
                            else {
                                resolve({[type]: {}})
                            }
                        })
                    });
                    promises.push(productPromise);
                }
            })
        }
        return Promise.all(promises).then(values => {
            values.forEach( array => {
                Object.keys(array).forEach( type => {
                    hardware[type]["brand"] = array[type].brand;
                    hardware[type]["style"] = array[type].style;
                    hardware[type]["finish"] = array[type].finish;
                    if (["passage", "interior", "entry"].includes(type)) {
                        hardware[type]["type"] = array[type].type;
                        hardware[type]["mode"] = array[type].mode;
                    }
                    if (["passage", "interior"].includes(type))
                        hardware[type]["direction"] = array[type].direction;
                })
            });
            return hardware;
        })
    };

    createCustomProduct = async (product, category = 'product') => {
        const { apolloClient } = this.props;
        delete product.description
        const {id, ...restProduct} = product;
        return Lib.saveCustomProduct({id: this.state.takeoffEditId, index: id, product: restProduct, category}, apolloClient);
    }

    render () {
        const {apolloClient} = this.props;
        return (
            <Provider value={{
                state: this.state,
                setState: (updater, callback = () => void 0) => this.setState( () => updater, callback),
                humanize: str => Lib.humanize(str),
                sendReadyEmail: async recepients => await Lib.notifyInsideOffice(recepients, this.state.takeoffEditId, apolloClient),
                notifyOffice: async (warehouse, reviewType) => {
                    const status = await Lib.notifyOffice(warehouse, reviewType, this.state.takeoffEditId, apolloClient);
                    if (status) this.setState({status})
                    return status
                },
                isSpecialSpecs: (category, type = null) => this.isSpecialSpecs(category, type),
                isEmptyTakeoff: () => {
                    return [
                        ...this.state.doors,
                        ...this.state.hardware,
                        ...this.state.trim,
                        ...this.state.windows,
                        ...this.state.shelving
                    ].length === 0
                },
                getPricingTier: () => this.state.customerType ? (Lib.deepAttributeExists(['pricingTier'], this.state[this.state.customerType]) || defaultPricingTier).toLowerCase() : defaultPricingTier,
                setMeasureDoorAttr: (field, value) => this.setState( prevState => ({
                    measures:{
                        ...prevState.measures,
                        door:{
                            ...prevState.measures.door,
                            [field]: value
                        }
                    }
                })),
                setMeasureOpenAttr: (field, value, clear = {}) => this.setState( prevState => ({
                    measures:{
                        ...prevState.measures,
                        custom: {
                            ...prevState.measures.custom,
                            window: {
                                ...prevState.measures.custom.window,
                                [field]: value,
                                ...(clear)
                            }
                        }
                    }
                })),
                updateCustomer: ({...customer}, type = 'customer') => {
                    if (this.isDoneTakeoff()) return false;
                    const otherType = type === 'customer' ? 'prospect' : 'customer';
                    let oldTier = this.state[otherType].pricingTier || defaultPricingTier;
                    let newTier = this.state[type].pricingTier || defaultPricingTier;
                    delete customer.id;
                    this.setState({
                        customerType: type,
                        [type]: customer,
                        customerListOpen: false
                    }, () => {
                        const updatePricing = oldTier !== newTier;
                        this.changeRemoteCustomer({customer, updatePricing, type});
                    })
                },
                addCustomProduct: async () => {
                    const custom_product = this.state.measures.custom.custom_product;
                    const product = await this.createCustomProduct(custom_product);
                    const custom_products = 'itemIndex' in custom_product ? 
                        this.state.custom_products.map( (item, i) => i === custom_product.itemIndex ? product : item) :
                        [...this.state.custom_products, product]

                    this.setState({
                        addDoorDialog: false,
                        productUpdated: this.state.productUpdated + 1,
                        custom_products
                    })
                },
                addCustomInstall: async () => {
                    // const { apolloClient } = this.props;
                    const custom_install_service = this.state.measures.custom.custom_install_service;
                    const product = await this.createCustomProduct(custom_install_service, 'install_service');
                    const custom_install_services = 'itemIndex' in custom_install_service ? 
                        this.state.custom_products.map( (item, i) => i === custom_install_service.itemIndex ? product : item) :
                        [...this.state.custom_install_services, product]
                    this.setState({
                        addDoorDialog: false,
                        productUpdated: this.state.productUpdated + 1,
                        custom_install_services
                    })
                },
                editMeasureCustomProduct: (index, type = 'product') => {
                    const product = this.state[`custom_${type}s`][index];
                    this.setState({
                        addDoorDialog: true,
                        measureTab: type === 'product' ? 'custom_product' : 'custom_install_service',
                        measures: {
                            ...this.state.measures,
                            custom: {
                                ...this.state.measures.custom,
                                [`custom_${type}`]: {
                                    itemIndex: index,
                                    id: product.id,
                                    code: product.product.code,
                                    description: product.product.description,
                                    type: product.type,
                                    count: product.count,
                                    notes: product.notes,
                                    images: product.images,
                                    additionalItem: product.additionalItem,
                                }
                            }
                        }
                    })
                },
                addMeasureDoor: async product => {
                    let itemIndex = this.state.editDoorIndex;
                    let door = {
                        id:              itemIndex !== -1 ? this.state.doors[itemIndex].id : null,
                        description:     product.description,
                        price:           product.pricingTiers,
                        code:            product.code,
                        additionalItem:  this.state.measures.door.additionalItem,
                        attributes:     {
                            type:            product.type,
                            supplier:        product.supplier,
                            style:           product.style,
                            core:            product.core !== null ? product.core : "",
                            height:          product.height,
                            thickness:       product.thickness,
                            frame:           product.frame,
                            finish:          product.finish,
                            material:        product.material,
                            sticking:        product.sticking,
                            panelProfile:    product.panelProfile,
                        },
                        width:                  product.width,
                        uom:                    product.uom,
                        isBifold:               product.type !== 'Utility' && product.bifold,
                        itemIndex:              this.state.measures.door.itemIndex,
                        unitNumber:             this.state.measures.door.unitNumber,
                        title:                  this.state.measures.door.title,
                        isDouble:               product.type !== 'Utility' && this.state.measures.door.isDouble,
                        hasCutdown:             product.type !== 'Utility' && this.state.measures.door.hasCutdown,
                        cutdownHeight:          product.type !== 'Utility' ? this.state.measures.door.cutdownHeight : 0,
                        isHalfTrim:             product.type !== 'Utility' && this.state.measures.door.isHalfTrim,
                        notes:                  this.state.measures.door.notes,
                        doorFunction:           this.state.measures.door.doorFunction,
                        handleDirection:        this.state.measures.door.handleDirection,
                        isReverse:              this.state.measures.door.isReverse,
                        handleType:             this.state.measures.door.handleType,
                        handleMode:             this.state.measures.door.handleMode,
                        jambWidth:              this.state.measures.door.jambWidth,
                        hingesPerDoor:          this.state.measures.door.hingesPerDoor,
                        doorstopper_mode:       this.state.measures.door.doorstopper_mode,
                        pocket_mode:            this.state.measures.door.pocket_mode,
                        doorstopper_mode_left:  this.state.measures.door.doorstopper_mode_left,
                        doorstopper_mode_right: this.state.measures.door.doorstopper_mode_right,
                        addArchitrave:          this.state.measures.door.addArchitrave,
                        //architraveWidth:        this.state.measures.door.architraveWidth || 0,
                        architraveSides:        this.state.measures.door.architraveSides,
                        addBackband:            this.state.measures.door.addBackband,
                        backbandSides:          this.state.measures.door.backbandSides,
                        materialType:           this.state.measures.door.materialType,
                        openingDepth:           this.state.measures.door.openingDepth,
                        quantity:               this.state.measures.door.quantity,
                        hasCustomHinge:         this.state.measures.door.hasCustomHinge,
                        hasCustomLockset:       this.state.measures.door.hasCustomLockset,
                        customHinges:           this.state.measures.door.customHinges,
                        customLockset:          this.state.measures.door.customLockset,
                        hasExtraService:        this.state.measures.door.hasExtraService,
                        extraService:           this.state.measures.door.extraService,
                        bipassTrackLength:      this.state.measures.door.bipassTrackLength,
                        cutdownLocation:        this.state.measures.door.hasCutdown ? this.state.measures.door.cutdownLocation : '',
                        isSpecial:              this.isSpecialSpecs('door'),
                        hardware:               {},
                        trim:                   {},
                        images:                 this.state.measures.door.images,
                        createdAt:              this.state.measures.door.createdAt,
                        updatedAt:              this.state.measures.door.updatedAt,
                    };
                    const hardware = this.state.specialSpecs.hardware;
                    if (this.state.measures.door.isDouble) {
                        door["latching"] = this.state.measures.door.latching;
                        door["operating"] = this.state.measures.door.operating;
                        door["leftHandleMode"] = this.state.measures.door.leftHandleMode;
                        door["rightHandleMode"] = this.state.measures.door.rightHandleMode;
                    }
                    let doorWTrim = door
                    if (Lib.deepAttributeExists(['door', 'type'], this.state.specialSpecs) !== 'Utility') {
                        const {casing, doorstop} = this.getDoorTrims(door);

                        door = update(door, {
                            trim: {
                                $set: {
                                    casing: casing,
                                    ...(
                                        door.doorFunction !== 'bypass' &&
                                        !door.isBifold &&
                                        { doorstop: doorstop }
                                    )
                                }
                            }
                        });
                        const doorWArchitrave   = await this.getDoorArchitrave(door);
                        const doorWBackband     = await this.getDoorBackband(doorWArchitrave);
                        // const doorWFlatStock   = await this.getDoorFlatStock(doorWBackband);
                        const doorWBurlap       = await this.getDoorBurlap(doorWBackband);
                        doorWTrim               = await this.addDoorJambs(doorWBurlap);
                    }
                    let doorWHinges         = doorWTrim;
                    if (Lib.deepAttributeExists(['door', 'type'], this.state.specialSpecs) !== 'Utility') {
                        doorWHinges         = await this.getDoorHinges(doorWTrim);
                    }
                    const fullDoor          = await this.addDoorHardware(doorWHinges, hardware);
                    const fullDoorWPrehang  = await this.addPreHangServices(fullDoor);
                    const fullDoorWServices = await this.addExtraServices('door', fullDoorWPrehang);
                    if (!fullDoorWServices?.id) {
                        this.setState(prevState => ({
                            addDoorDialog: false,
                            productUpdated: this.state.productUpdated + 1,
                            doors: [...prevState.doors, fullDoorWServices]
                        }), async () => {
                            await this.clearMeasureForm();
                            await this.saveMeasureItem(
                                {
                                    category: 'doors',
                                    item: fullDoorWServices
                                }
                            );
                        })
                    }
                    else {
                        let doors = this.state.doors;
                        doors[itemIndex] = fullDoorWServices;
                        this.setState({
                            addDoorDialog: false,
                            productUpdated: this.state.productUpdated + 1,
                            doors: doors
                        }, async () => {
                            await this.clearMeasureForm();
                            await this.saveMeasureItem(
                                {
                                    category: 'doors',
                                    item: fullDoorWServices,
                                    index: itemIndex
                                })
                        })
                    }
                },
                editMeasureDoor: index => {
                    const door = this.state.doors[index];
                    this.copySpecs(
                        'specs',
                        'specialSpecs',
                        'door',
                        '',
                        this.state.doors[index].attributes
                    );

                    let obj = {
                        editDoorIndex:          index,
                        addDoorDialog:          true ,
                        specialSpecsExpanded:   door.isSpecial ,
                        measureTab:             "door",
                        specialSpecs: {
                            ...this.state.specialSpecs,
                            door: {
                                ...this.state.specialSpecs.door,
                                ...(door.attributes || {}),
                            }
                        },
                        measures: {
                            ...this.state.measures,
                            door: {
                                ...this.state.measures.door,
                                id: door.id,
                                itemIndex:  door.itemIndex,
                                unitNumber: door.unitNumber,
                                title:      door.title,
                                additionalItem:  door.additionalItem,
                                width: door.width,
                                images: door.images,
                                isHalfTrim: door.isHalfTrim,
                                hasExtraService: door.hasExtraService,
                                extraService: door.extraService,
                                cutdownHeight: door.cutdownHeight,
                                hasCutdown: door.hasCutdown,
                                isBifold: door.isBifold,
                                isDouble: door.isDouble,
                                doorFunction: door.doorFunction,
                                handleDirection: door.handleDirection,
                                isReverse: door.isReverse,
                                isSpecial: door.isSpecial,
                                handleType: door.handleType,
                                handleMode: door.handleMode,
                                notes: door.notes,
                                hingesPerDoor: door.hingesPerDoor,
                                pocket_mode: door.pocket_mode,
                                doorstopper_mode: door.doorstopper_mode,
                                doorstopper_mode_left: door.doorstopper_mode_left,
                                doorstopper_mode_right: door.doorstopper_mode_right,
                                operating: door.operating,
                                latching:  door.latching,
                                leftHandleMode:  door.leftHandleMode,
                                rightHandleMode: door.rightHandleMode,
                                addBackband: door.addBackband,
                                backbandSides: door.backbandSides,
                                addArchitrave: door.addArchitrave,
                                architraveSides: door.architraveSides,
                                bipassTrackLength: door.bipassTrackLength,
                                architraveWidth: door.architraveWidth || 0,
                                materialType:       door.materialType || '',
                                openingDepth:       door.openingDepth || 0,
                                quantity:           door.quantity || '',
                                hasCustomHinge:     door.hasCustomHinge,
                                hasCustomLockset:   door.hasCustomLockset,
                                customHinges:       door.customHinges,
                                customLockset:      door.customLockset,
                                cutdownLocation:    door.cutdownLocation,
                                createdAt:      door.createdAt,
                                updatedAt:      door.updatedAt,
                                // addBackband: this.state.measures.door.addBackband,
                            }
                        }
                    };
                    if (
                        door.doorFunction === "" ||
                        ["swing", "bifold"].includes(door.doorFunction)
                    ) {
                        this.setState(obj);
                    }
                    else {
                        // get door hardware attributes
                        let hardwarePromise = this.getDoorHardwareAttributes(door)
                        hardwarePromise.then (hardware => {
                            obj = update(obj, {
                                measures: {
                                    door:{
                                        hardware: {$set: hardware}
                                    }
                                }
                            });
                            this.setState(obj);
                        })
                    }
                },
                addOpening: async type => {
                    const openings = this.state.windows;
                    const index = this.state.editWindowIndex;
                    const newOpening = this.addAnOpening(type);
                    newOpening.then ( async opening => {
                        if (opening) {
                            const openingWServices = await this.addExtraServices('opening', opening);
                            const objIndex = openingWServices.id ? openings.findIndex(obj => obj.id === openingWServices.id) : openings.length;
                            const newOpeningArray = [
                                ...openings.slice(0, objIndex),
                                openingWServices,
                                ...openings.slice(objIndex + 1),
                            ];
                            this.setState({
                                addDoorDialog: false,
                                productUpdated: this.state.productUpdated + 1,
                                editWindowIndex: -1,
                                windows: newOpeningArray
                            }, async () => {
                                await this.clearMeasureForm()
                                await this.saveMeasureItem(
                                    {
                                        category: 'windows',
                                        item: openingWServices,
                                        index: index > -1 ? index : null
                                    })
                            });
                        }
                    });
                },
                editMeasureWindow: index => {
                    let window = this.state.windows[index];
                    let newState = update(this.state, {
                        editWindowIndex:    {$set: index },
                        measureTab:         {$set: "window" },
                        addDoorDialog:      {$set: true },
                        measures: {
                            custom: {
                                window: {
                                    id:                 {$set: window.id },
                                    itemIndex:          {$set: window.itemIndex },
                                    unitNumber:         {$set: window.unitNumber },
                                    title:              {$set: window.title },
                                    additionalItem:     {$set: window.additionalItem },
                                    type:               {$set: window.type },
                                    notes:              {$set: window.notes },
                                    images:             {$set: window.images },
                                    createdAt:          {$set: window.createdAt },
                                    updatedAt:          {$set: window.updatedAt },
                                }
                            }
                        }
                    });
                    switch (window.type) {
                        case "window":
                            newState = update(newState,{
                                measures: {
                                    custom: {
                                        window: {
                                            size:               {$set: window.size},
                                            width:              {$set: window.width},
                                            height:             {$set: window.height},
                                            addBackband:        {$set: window.addBackband},
                                            addArchitrave:      {$set: window.addArchitrave},
                                            architraveWidth:    {$set: window.architraveWidth || 0},
                                            addBuildout:        {$set: window.addBuildout},
                                            count:              {$set: window.count},
                                            buildoutDepth:      {$set: window.buildoutDepth},
                                            sillDepth:          {$set: window.sillDepth},
                                            sillLength:         {$set: window.sillLength},
                                            addSill:            {$set: window.addSill},
                                            isHigh:             {$set: window.isHigh},
                                            highOpeningLocation:{$set: window.highOpeningLocation},
                                            hasBurlap:          {$set: window.hasBurlap},
                                            burlapCount:        {$set: window.trim?.burlap?.count},
                                            quantity:           {$set: window.quantity},
                                            materialType:       {$set: window.materialType},
                                            openingDepth:       {$set: window.openingDepth},
                                            hasExtraService:    {$set: window.hasExtraService},
                                            extraService:       {$set: window.extraService},
                                        }
                                    }
                                }
                            });
                            this.setState(newState);
                            break;
                        case "exterior_door":
                            newState = update(newState,{
                                measures: {
                                    custom: {
                                        window: {
                                            size:               {$set: window.size},
                                            architraveWidth:    {$set: window.architraveWidth || 0},
                                            addArchitrave:      {$set: window.addArchitrave},
                                            addBackband:        {$set: window.addBackband},
                                            addBuildout:        {$set: window.addBuildout},
                                            buildoutDepth:      {$set: window.buildoutDepth},
                                            hardwareOptions:    {$set: window.hardwareOptions},
                                            hasCloser:          {$set: window.hasCloser},
                                            hasBurlap:          {$set: window.hasBurlap},
                                            quantity:           {$set: window.quantity},
                                            materialType:       {$set: window.materialType},
                                            openingDepth:       {$set: window.openingDepth},
                                            closerCount:        {$set: window.closerCount},
                                            doorstopper_mode:       {$set: window.doorstopper_mode},
                                            doorstopper_mode_left:  {$set: window.doorstopper_mode_left},
                                            doorstopper_mode_right: {$set: window.doorstopper_mode_right},
                                            operating:              {$set: window.operating},
                                            handleType:             {$set: window.handleType},
                                            burlapCount:        {$set: window.trim?.burlap?.count},
                                            extHardwareMode:        {$set: window.extHardwareMode},
                                            extHardwareMode2:       {$set: window.extHardwareMode2},
                                            extHandleMode:          {$set: window.extHandleMode},
                                            extHandleMode2:         {$set: window.extHandleMode2},
                                            // hardware:           {$set: hardwareObject}
                                        }
                                    }
                                }
                            });
                            this.setState(newState)
                            break;
                        case "archway":
                            newState = update(newState,{
                                specialSpecs:{
                                    sheet: {
                                        $set :
                                            Lib.deepAttributeExists(['sheet', 'code'], window) &&
                                            Object.keys(window.sheet.attributes).length > 0 ?
                                                {
                                                    ...window.sheet.attributes,
                                                } : {}
                                    }
                                },
                                measures: {
                                    custom: {
                                        window: {
                                            size: {$set: window.size},
                                            width: {$set: window.width},
                                            height: {$set: window.height},
                                            quantity: {$set: window.quantity},
                                            materialType: {$set: window.materialType},
                                            customHeader: {$set: window.customHeader},
                                            headerQuantity: {$set: window.headerQuantity},
                                            headerMaterial: {$set: window.headerMaterial},
                                            openingDepth: {$set: window.openingDepth},
                                            addArchitrave: {$set: window.addArchitrave},
                                            architraveSides: {$set: window.architraveSides},
                                            addBackband: {$set: window.addBackband},
                                            backbandSides: {$set: window.backbandSides},
                                            hasExtraService:    {$set: window.hasExtraService},
                                            extraService:       {$set: window.extraService},
                                        }
                                    }
                                }
                            });
                            this.setState(newState);
                            break
                        case "caps":
                            newState = update(newState,{
                                specialSpecs:{
                                    sheet: {
                                        $set :
                                            Lib.deepAttributeExists(['sheet', 'code'], window) &&
                                            Object.keys(window.sheet.attributes).length > 0 ?
                                                {
                                                    ...window.sheet.attributes,
                                                } : {}
                                    }
                                },
                                measures: {
                                    custom: {
                                        window: {
                                            width: {$set: window.width},
                                            height: {$set: window.height},
                                            layout: {$set: window.layout},
                                            quantity: {$set: window.quantity},
                                            materialType: {$set: window.materialType},
                                            hasExtraService:    {$set: window.hasExtraService},
                                            extraService:       {$set: window.extraService},
                                        }
                                    }
                                }
                            });
                            this.setState(newState);
                            break;
                        case "arch_window":
                            newState = update(newState,{
                                specialSpecs:{
                                    trim: {
                                        curve: {
                                            $set :
                                                window.trim &&
                                                "curve" in window.trim &&
                                                window.trim.curve &&
                                                "attributes" in window.trim.curve &&
                                                window.trim.curve.attributes &&
                                                Object.keys(window.trim.curve.attributes).length > 0 ?
                                                    {...window.trim.curve.attributes} : {}
                                        }
                                    }
                                },
                                measures: {
                                    custom: {
                                        window: {
                                            width:  {$set: window.width},
                                            height: {$set: window.height},
                                            isHigh: {$set: window.isHigh},
                                            highOpeningLocation: {$set: window.highOpeningLocationh},
                                        }
                                    }
                                }
                            });
                            this.setState(newState);
                            break;
                        case "shower_stall":
                            newState = update(newState,{
                                measures: {
                                    custom: {
                                        window: {
                                            itemIndex:              {$set: window.itemIndex},
                                            title:                  {$set: window.title},
                                            materialType:           {$set: window.materialType},
                                        }
                                    }
                                }
                            });
                            this.setState(newState);
                            break;
                        case "attic":
                            this.setState(newState);
                            break;
                        case "stairs":
                            newState = update(newState,{
                                measures: {
                                    custom: {
                                        window: {
                                            itemIndex:              {$set: window.itemIndex},
                                            title:                  {$set: window.title},
                                            layout:                 {$set: window.layout},
                                            quantity:               {$set: window.quantity},
                                            attributes:             {$set: window.attributes},
                                            materialType:           {$set: window.materialType},
                                            continuousHandrail:     {$set: window.continuousHandrail},
                                            addHandrail:            {$set: window.addHandrail},
                                            addCasing:              {$set: window.addCasing},
                                            addD4s:                 {$set: window.addD4s},
                                            materialLength:         {$set: window.materialLength},
                                            coveLength:             {$set: window.coveLength},
                                            d4sLength:              {$set: window.d4sLength},
                                            casingLength:           {$set: window.casingLength},
                                            handrailLength:         {$set: window.handrailLength},
                                        }
                                    }
                                }
                            });
                            this.setState(newState);
                            break;
                        case "column":
                            newState = update(newState,{
                                measures: {
                                    custom: {
                                        window: {
                                            itemIndex:              {$set: window.itemIndex},
                                            title:                  {$set: window.title},
                                            addSplit:               {$set: window.addSplit},
                                        }
                                    }
                                }
                            });
                            this.setState(newState);
                            break;
                        default:

                    }

                },
                addMeasureHardware: type => {
                    const index = this.state.editHardwareIndex;
                    let product = {
                        id: index !== -1 ? this.state.hardware[index].id : null,
                        product: {
                            ...this.state.specialSpecs.products[type],
                            isSpecial: this.isSpecialSpecs('hardware', type.toLowerCase())
                        },
                        type:       type,
                        count:      this.state.measures.custom.hardware.count,
                        additionalItem: this.state.measures.custom.hardware.additionalItem,
                        notes:      this.state.measures.custom.hardware.notes,
                        images:     this.state.measures.custom.hardware.images,
                        itemIndex:  this.state.measures.custom.hardware.itemIndex,
                        unitNumber: this.state.measures.custom.hardware.unitNumber,
                        title:      this.state.measures.custom.hardware.title,
                        createdAt:  this.state.measures.custom.hardware.createdAt,
                        updatedAt:  this.state.measures.custom.hardware.updatedAt,
                    };
                    if (!product.id) {
                        this.setState({
                            productUpdated: this.state.productUpdated + 1,
                            hardware: [
                                ...this.state.hardware,
                                product
                            ]
                        }, async () => {
                            await this.clearMeasureForm();
                            await this.saveMeasureItem(
                                {
                                    category: 'hardware',
                                    item: product,
                                })
                        })
                    }
                    else {
                        const newState = update(this.state, {
                            editHardwareIndex:      {$set: -1},
                            productUpdated:         {$set: this.state.productUpdated + 1},
                            addDoorDialog:          {$set: false},
                            specialSpecsExpanded:   {$set: false},
                            specialSpecs:           {$set: specs},
                            measures: {
                                custom:{
                                    hardware: {
                                        type:   {$set: ""},
                                        notes:  {$set: ""},
                                        images: {$set: []},
                                        count:  {$set: 0}
                                    }
                                },
                            },
                            hardware:{
                                [this.state.editHardwareIndex] : {$set: product}
                            }
                        });
                        this.setState(newState, async () => {
                            this.clearMeasureForm();
                            await this.saveMeasureItem(
                                {
                                    category: 'hardware',
                                    item: product,
                                    index
                                })
                        })
                    }
                },
                editMeasureHardware: index => {
                    const product = this.state.hardware[index];
                    const newState = update(this.state, {
                        editHardwareIndex:      {$set: index },
                        measureTab:             {$set: "hardware" },
                        addDoorDialog:          {$set: true },
                        specialSpecsExpanded:   {$set: true },
                        specialSpecs: {
                            hardware: {
                                [product.type]: {$set: product.product.attributes}
                            },
                            products:{
                                [product.type]: {$set: product.product}
                            }
                        },
                        measures: {
                            custom: {
                                hardware: {
                                    id:                 {$set: product.id },
                                    itemIndex:          {$set: product.itemIndex },
                                    unitNumber:         {$set: product.unitNumber },
                                    title:              {$set: product.title },
                                    additionalItem:     {$set: product.additionalItem },
                                    type:               {$set: product.type},
                                    count:              {$set: product.count},
                                    notes:              {$set: product.notes},
                                    images:             {$set: product.images},
                                    createdAt:          {$set: product.createdAt },
                                    updatedAt:          {$set: product.updatedAt },
                                },
                            }
                        }
                    })
                    this.setState(newState)
                },
                addMeasureTrim: async type => {
                    const index = this.state.editTrimIndex;
                    const trim = this.state.measures.custom.trim;
                    let product = {
                        id: index !== -1 ? this.state.trim[index].id : null,
                        product: {
                            ...this.state.specialSpecs.products[type],
                            isSpecial: this.isSpecialSpecs('trim', type.toLowerCase())
                        },
                        type:           type,
                        count:          trim.count,
                        sqft:           trim.sqft,
                        notes:          trim.notes,
                        images:         trim.images,
                        itemIndex:      trim.itemIndex,
                        unitNumber:     trim.unitNumber,
                        title:          trim.title,
                        additionalItem: trim.additionalItem,
                        ...(
                            'attributes' in trim &&
                            trim.attributes &&
                            Object.keys(trim.attributes).length > 0 &&
                            {attributes: trim.attributes}
                        ),
                        createdAt:  trim.createdAt,
                        updatedAt:  trim.updatedAt,
                    };
                    const trimWServices = await this.addExtraServices(
                        'trim', 
                        product, 
                        this.state.measures.custom.trim.extra_service || "",
                        this.state.measures.custom.trim.serviceCount || 0
                    );
                    if (!trim.id) {
                        this.setState({
                            productUpdated: this.state.productUpdated + 1,
                            trim: [
                                ...this.state.trim,
                                trimWServices
                            ]
                        }, async () => {
                            await this.clearMeasureForm()
                            await this.saveMeasureItem(
                                {
                                    category: 'trim',
                                    item: trimWServices,
                                })
                        })
                    }
                    else {
                        const newState = update(this.state, {
                            editTrimIndex: {$set: -1},
                            productUpdated: {$set: this.state.productUpdated + 1},
                            addDoorDialog: {$set: false},
                            specialSpecsExpanded: {$set: false},
                            specialSpecs: {$set: specs},
                            measures: {
                                custom:{
                                    trim: {
                                        type: {$set: ""},
                                        notes: {$set: ""},
                                        images: {$set: []},
                                        count: {$set: 0}
                                    }
                                },
                            },
                            trim:{
                                [this.state.editTrimIndex] : {$set: trimWServices}
                            }
                        });
                        this.setState(newState, async () => {
                            await this.clearMeasureForm();
                            await this.saveMeasureItem(
                                {
                                    category: 'trim',
                                    item: trimWServices,
                                    index
                                })
                        })
                    }
                },
                editMeasureTrim: index => {
                    const product = this.state.trim[index];
                    const service = Lib.deepAttributeExists([0, 'attributes', 'type'], product.install_service);
                    const serviceCount = Lib.deepAttributeExists([0, 'count'], product.install_service);
                    const newState = update(this.state, {
                        editTrimIndex:          {$set: index },
                        measureTab:             {$set: "trim" },
                        addDoorDialog:          {$set: true },
                        specialSpecsExpanded:   {$set: true },
                        specialSpecs: {
                            trim: {
                                [product.type]: {$set: product.product.attributes}
                            },
                            products:{
                                [product.type]: {$set: product.product}
                            }
                        },
                        measures: {
                            custom: {
                                trim: {
                                    id:             {$set: product.id },
                                    itemIndex:      {$set: product.itemIndex },
                                    unitNumber:     {$set: product.unitNumber },
                                    title:          {$set: product.title },
                                    additionalItem: {$set: product.additionalItem },
                                    type:           {$set: product.type},
                                    count:          {$set: product.count},
                                    notes:          {$set: product.notes},
                                    images:         {$set: product.images},
                                    extra_service:  {$set: service},
                                    serviceCount:   {$set: serviceCount},
                                    attributes:     {$set: product.attributes},
                                    createdAt:      {$set: product.createdAt },
                                    updatedAt:      {$set: product.updatedAt },
                                },
                            }
                        }
                    });
                    this.setState(newState)
                },
                addShelving2: async () => {
                    const shelf = this.state.measures.custom.shelving;
                    const product = await this.createShelf(shelf);
                    const shelves = 'itemIndex' in shelf ? 
                        this.state.shelving.map( (item, i) => i === shelf.itemIndex ? product : item) :
                        [...this.state.shelving, product]
                    this.clearMeasureForm();
                    this.setState({
                        addDoorDialog: false,
                        productUpdated: this.state.productUpdated + 1,
                        editShelvingIndex: -1,
                        specialSpecs: {
                            ...this.state.specialSpecs,
                            shelving:{
                                ...this.state.specialSpecs.shelving,
                                organizer: {}
                            }
                        },
                        shelves
                    })
                },
                addShelving: async () => {
                    const index = this.state.editShelvingIndex;
                    const newShelving = await this.addMeasureShelving();
                    if (newShelving) {
                        const newShelvingState =
                        newShelving.id ?
                                update(this.state.shelving, {
                                    [index]: {"$set": newShelving}
                                }) :
                                [...this.state.shelving, newShelving]
                        this.setState({
                            addDoorDialog: false,
                            productUpdated: this.state.productUpdated + 1,
                            editShelvingIndex: -1,
                            shelving: newShelvingState,
                            specialSpecs: {
                                ...this.state.specialSpecs,
                                shelving:{
                                    ...this.state.specialSpecs.shelving,
                                    organizer: {}
                                }
                            }
                        }, async () => {
                            this.clearMeasureForm();
                            await this.saveMeasureItem(
                                {
                                    category: 'shelving',
                                    item: newShelving,
                                    index
                                })
                        })
                    } else {
                        this.setState({
                            addDoorDialog: false,
                            editShelvingIndex: -1
                        })
                    }
                },
                editShelving: i => {
                    let shelving = this.state.shelving[i];
                    let newState = update(this.state, {
                        editShelvingIndex:  {$set: i },
                        measureTab:         {$set: "shelving" },
                        addDoorDialog:      {$set: true },
                        ...(
                            shelving.type === 'organizer' && {
                                specs: {
                                    shelving: {
                                        organizer: { $set: 
                                            shelving.product?.attributes || 
                                            shelving.wallDesigns?.wall1.designs[0]?.product?.attributes ||
                                            shelving.wallDesigns?.wall2.designs[0]?.product?.attributes ||
                                            shelving.wallDesigns?.wall3.designs[0]?.product?.attributes
                                        },
                                    }
                                }
                            }
                        ),
                        measures: {
                            custom: {
                                shelving: {
                                    id:                     {$set: shelving.id },
                                    itemIndex:              {$set: shelving.itemIndex },
                                    unitNumber:             {$set: shelving.unitNumber },
                                    title:                  {$set: shelving.title },
                                    additionalItem:         {$set: shelving.additionalItem },
                                    type:                   {$set: shelving.type },
                                    layout:                 {$set: shelving.layout },
                                    depth:                  {$set: shelving.depth },
                                    quantity:               {$set: shelving.quantity },
                                    width:                  {$set: shelving.width },
                                    dimensions:             {$set: shelving.dimensions },
                                    miscellaneous:          {$set: shelving.miscellaneous },
                                    notes:                  {$set: shelving.notes },
                                    images:                 {$set: shelving.images },
                                    addedInstall:           {$set: shelving.addedInstall },
                                    hasInstall:             {$set: shelving.install_service.filter(a => a.attributes?.type === 'Organizer').length > 0 ? true : false },
                                    hasCallout:             {$set: shelving.install_service.filter(a => a.attributes?.type === 'Call out charge').length > 0 ? true : false },
                                    hasAccessoryInstall:    {$set: shelving.install_service.filter(a => a.attributes?.type === 'Organizer Accessories').length > 0 ? true : false },
                                    accessories:            {$set: shelving.accessories.map(accessory => ({
                                        specs:              accessory.attributes,
                                        quantity:           ''+accessory.count,
                                    })) },
                                    wallDesigns:            {$set: shelving.wallDesigns },
                                    additional_brackets:     {$set: shelving.additional_brackets },
                                    additional_rods:     {$set: shelving.additional_rods },
                                    rod_finish: {$set: (((shelving.hardware || {}).rod || {}).attributes || {}).finish },
                                    createdAt:  {$set: shelving.createdAt },
                                    updatedAt:  {$set: shelving.updatedAt },
                                }
                            }
                        }
                    });
                    this.setState(newState)
                },
                removeMeasureItem: (category, index) => {
                    const id = this.state[category][index] ? this.state[category][index].id : '';
                    const itemIndex = this.state[category][index] ? this.state[category][index].itemIndex : '';
                    if (id) {
                        let newState = update(this.state,{
                            deleteMeasureItem: {$set : {}},
                            productUpdated: {$set : this.state.productUpdated + 1},
                            [category]: {$splice: [[index, 1]]}
                        });
                        this.setState(newState, async () => await this.removeMeasureItem({category,index: id, itemIndex}))
                    }
                },
                closeDoorDialog : async ( obj = {}) => {
                    await this.clearMeasureForm(obj)
                },
                deleteTypeImage: async (index, type) => {
                    let images = [...this.state.measures.custom[type].images];
                    images.splice(index, 1);
                    this.setState({
                        measures: {
                            ...this.state.measures,
                            custom: {
                                ...this.state.measures.custom,
                                [type]: {
                                    ...this.state.measures.custom[type],
                                    images: images,
                                }
                            }
                        }
                    })
                },
                deleteDoorImage: async index => {
                    let doorImages = [...this.state.measures.door.images];
                    doorImages.splice(index, 1);
                    this.setState({
                        measures: {
                            ...this.state.measures,
                            door: {
                                ...this.state.measures.door,
                                images: doorImages,
                            }
                        }
                    })
                },
                isSpecSet : (field, category, type="") => {
                    return this.isSpecSet(field, category, type);
                },
                isSpecProductSet: (type, scope = 'specs') => {
                    if (Lib.deepAttributeExists([type, 'code'], this.state[scope].products))
                        return true;
                    else
                        return false;
                },
                getAvailableDimensions: (category, type, refSpecs = {}) => {
                    let specs = refSpecs ? refSpecs : type === "" ? this.state.specs[category] : this.state.specs[category][type];
                    let conditions = {
                        ...specs,
                        category: category === "door"? "Door" : Lib.humanize(category),
                        type: type ? Lib.humanize(type) : refSpecs.type || ""
                    };
                    if ("trimlength" in conditions)
                        delete conditions.trimlength;
                    else if (category === "door") {
                        delete conditions.prep
                        delete conditions.backset
                        delete conditions.head
                    }
                    return Lib.getDimensions(conditions, apolloClient)
                },
                getAnyProduct: (attributes, moreAttributes = []) => {
                    if ('extra_specs' in attributes)
                        delete attributes.extra_specs;
                    return Lib.getAnyProduct(attributes, apolloClient, moreAttributes)
                },
                copySpecs: (from, to, category, type="", attributes = null) => {
                    this.copySpecs(from, to, category, type, attributes);
                },
                openMeasure: type => {
                    this.setState({
                        measureDrawer: false,
                        measureTab: type,
                        addDoorDialog: true,
                    })
                },
                saveSpecs: ({scope = 'specs', category, type = null, specs = null, product = {}, copy = false, propagate = false}) => {
                    delete product.isSpecial;
                    this.setState({
                        ...(
                            type === 'opening_service' && {productUpdated: this.state.productUpdated + 1}
                        ),
                        specs:{
                            ...this.state.specs,
                            ...(
                                (scope === 'specs' || copy) &&
                                {
                                    ...(
                                        category !== 'door' &&
                                        {
                                            products: {
                                                ...this.state.specs.products,
                                                ...(type || category === 'sheet' ? {[type] : product} : {[category] : product} )
                                            }
                                        }
                                    ),
                                    ...(
                                        type !== 'opening_service' &&
                                        {
                                            [category]: {
                                                ...this.state.specs[category],
                                                ...(type ? {[type] : specs} : {...specs} ),
                                            }
                                        }
                                    )
                                }
                            )
                        },
                        specialSpecs: {
                            ...this.state.specialSpecs,
                            ...(
                                scope === 'specialSpecs' &&
                                {
                                    products: {
                                        ...this.state.specialSpecs.products,
                                        ...(type ? {[type] : product} : {[category] : product} )
                                    },
                                    [category]: {
                                        ...this.state.specialSpecs[category],
                                        ...(type ? {[type] : specs} : {...specs} ),
                                    }
                                }
                            )
                        },
                    }, async () => {
                        if (scope === 'specs' || copy) {
                            await this.saveRemoteSpecs({category, type, specs, product, propagate})
                        }
                    });
                },
                getLabourCost: (attributes) => {
                    return Lib.getLabourItem(attributes, apolloClient)
                },
                duplicateMeasureItemDialog: (category, index) => {
                    this.setState({
                        duplicateMeasureItem: {
                            category,
                            index
                        }
                    })
                },
                duplicateMeasureItem: (count) => {
                    const category = this.state.duplicateMeasureItem.category;
                    const categoryItems = this.state[this.state.duplicateMeasureItem.category];
                    const index = this.state.duplicateMeasureItem.index;
                    const item = categoryItems[index];
                    const maxIndex = Math.max(
                        ...categoryItems
                            .filter(o => o && item && (['doors', 'shelving'].includes(category) || o.type === item.type))
                            .map(o => 'itemIndex' in o ? o.itemIndex : 0)
                        , 0);
                    const duplicates = [];
                    for (let i = 0; i < count; i++) {
                        duplicates.push({
                            ...item,
                            itemIndex: maxIndex + (i+1)
                        })
                    }
                    categoryItems.push(...duplicates);
                    this.setState({
                        productUpdated: this.state.productUpdated + 1,
                        duplicateMeasureItem: {},
                        [category]: categoryItems
                    }, async () => {
                        await this.clearMeasureForm();
                        await this.remoteDuplicateMeasureItem(
                            {
                                category,
                                index,
                                maxIndex,
                                count
                            })
                    })
                },
                deleteMeasureItemDialog: (category, index) => {
                    this.setState({
                        deleteMeasureItem: {
                            category,
                            index
                        }
                    })
                },
                saveSiteDetails: (site = {}, callback = () => {}) => {
                    this.setState({site}, () => {
                        this.saveRemoteAttribute({
                            category: 'site',
                            site
                        })
                        callback()
                    })
                },
                saveAdditionalOrderInfo: (additionalOrder) => {
                    this.setState({additionalOrder}, () => (
                        Lib.saveAdditionalOrderInfo(
                            {
                                id: this.state.takeoffEditId, 
                                additionalOrder: additionalOrder
                            }
                            , apolloClient
                        )
                    ))
                },
                saveInstallPricing: (category, value = '') => {
                    if (this.isDoneTakeoff()) return false;
                    const cleanCutdownTreatmentSpec = category === 'installPricingMode' &&
                                                        value !== 'contract_pricing' &&
                                                        this.state.specs.door_treatment.cutdown === 'onsite' ?
                                                        {
                                                        ...this.state.specs.door_treatment,
                                                        cutdown: ''
                                                        } : null;
                    this.setState({
                        [category]: value && category === "installAt" ? new Date(value) : value,
                        ...(cleanCutdownTreatmentSpec && {
                          specs: {
                            ...this.state.specs,
                            door_treatment: cleanCutdownTreatmentSpec
                          }
                        })
                    }, async () => {
                        await this.saveRemoteAttribute({
                            category,
                            value : value && category === "installAt" ? new Intl.DateTimeFormat('en-US').format(value) : (value || '')
                        })
                        if (cleanCutdownTreatmentSpec) {
                            await this.saveRemoteSpecs({
                                category: 'door_treatment',
                                specs: cleanCutdownTreatmentSpec,
                            })
                        }
                        if (
                            category === 'installPricingMode' &&
                            value
                        ) {
                            const {baseboard, casing} = this.state.specs.trim;
                            const attributes = {
                                supplier: casing.supplier || baseboard.supplier || "Commodity",
                                casing: Lib.parseStringNumber( casing ? casing.width || 0 : 0),
                                baseboard: Lib.parseStringNumber(baseboard ? baseboard.width || 0 : 0)
                            }
                            const openeningServicePromise = Lib.getLabourItem({
                                category: 'Install',
                                type: 'Opening',
                                attributes
                            }, apolloClient);
                            openeningServicePromise.then(opening => {
                                if (opening) {
                                    const {pricingTiers, ...service} = opening;
                                    const opening_service = {
                                        ...service,
                                        price: pricingTiers,
                                        attributes
                                    };
                                    this.setState({
                                        productUpdated: this.state.productUpdated + 1,
                                        specs: {
                                            ...this.state.specs,
                                            products: {
                                                ...this.state.specs.products,
                                                opening_service
                                            }
                                        }
                                    }, () => {
                                        this.saveRemoteSpecs({
                                            category: 'products',
                                            type: 'opening_service',
                                            product: opening_service
                                        });
                                    });
                                }
                            })
                        }
                    })
                },
                saveDoorTreatment: async (item, value, propagate = false) => {
                    const door_treatment = {
                        ...this.state.specs.door_treatment,
                        [item]: value,
                        ...(item === 'prep' && value === 'loose' && {
                            head: '',
                            backset: ''
                        }),
                        ...(
                          item === 'prep' &&
                          value !== 'machined' &&
                          this.state.specs.door_treatment.cutdown === 'inshop' &&
                          {
                            cutdown: ''
                          }
                        )
                    };
                    const newSpecs = {
                        ...this.state.specs,
                        door_treatment
                    };

                    this.setState({
                        specs: newSpecs
                    }, async () => (
                        await this.saveRemoteSpecs({
                            category: 'door_treatment',
                            specs: door_treatment,
                            propagate
                        })
                    ))
                },
                removeCustomer: () => {
                    const customer  = {};
                    const type      = "";
                    let oldTier     = this.state[this.state.customerType].pricingTier || defaultPricingTier;
                    this.setState({
                        customerType:   type,
                        customer:       customer,
                        prospect:       customer,
                    }, () => {
                        const updatePricing = oldTier !== defaultPricingTier;
                        this.changeRemoteCustomer({customer, updatePricing, type});
                    })
                },
                getCustomer: () => {
                    return this.state.customerType && this.state[this.state.customerType]
                },
                saveSpecsNotes: (category, value) => {
                    const notes = {
                        ...this.state.specs.notes,
                        [category.toLowerCase()]: value
                    };
                    this.setState({
                        specs: {
                            ...this.state.specs,
                            notes
                        }
                    }, () => {
                        this.saveRemoteSpecs({category: 'notes', notes})
                    })
                },
                isDoneTakeoff: () => this.isDoneTakeoff(),
                setPrimaryTab: (tab) => {
                    this.setPrimaryTab(tab);
                },
                refreshTakeoffData: () => {
                    if (this.state.takeoffEditId) this.refreshTakeoffData(this.state.takeoffEditId, apolloClient)
                },
                updatePublicLink: (publicCode, linkExpiresAt) => {
                    this.setState({publicCode, linkExpiresAt});
                }

            }}>
                {this.props.children}
            </Provider>
        )
    }
}

const TakeoffFormProvider = withRouter(FormProvider)
export { TakeoffFormProvider , Consumer as TakeoffFormConsumer, TakeoffFormContext}
